import React from "react";
import { Typography, Fade, Switch, FormControlLabel } from "@material-ui/core";
import { Row, Col, Flex } from '../../Shared/Grid';
import IndividualProfileComponent from "./IndividualProfileComponent";
import validators from "../../validators";

export default class ProfilesViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.firstFieldRef = React.createRef();

    this.state = {
      primary: props.value ? props.value[0] : null,
      secondary: props.value ? props.value[1] : null,
      includeSecondary: props.value ? props.value[1] != null : false
    };

    this.state.primaryErrors = validators.validateProfile(this.state.primary || {});
    this.state.secondaryErrors = validators.validateProfile(this.state.secondary || {});

    this.propagateChange(this.state.primary, this.state.primaryErrors, this.state.secondary, this.state.secondaryErrors, this.state.includeSecondary);
  }

  componentDidMount = () => {
    if (this.props.onViewInit)
      this.props.onViewInit();
  }

  handleSlideEntered = () => {
    if (this.firstFieldRef.current)
      this.firstFieldRef.current.focus();
  }

  handlePrimaryChange = (profile) => {
    var errors = validators.validateProfile(profile || {});
    this.setState({ primary: profile, primaryErrors: errors });
    this.propagateChange(profile, errors, this.state.secondary, this.state.secondaryErrors, this.state.includeSecondary);
  }

  handleSecondaryChange = (profile) => {
    var errors = validators.validateProfile(profile || {});
    this.setState({ secondary: profile, secondaryErrors: errors });
    this.propagateChange(this.state.primary, this.state.primaryErrors, profile, errors, this.state.includeSecondary);
  }

  handleIncludeSecondary = (value) => {
    this.setState({ includeSecondary: value });
    this.propagateChange(this.state.primary, this.state.primaryErrors, this.state.secondary, this.state.secondaryErrors, value);
  }

  propagateChange = (primary, primaryErrors, secondary, secondaryErrors, includeSecondary) => {
    if (this.props.onChange) {
      var primaryValid = validators.isValid(primaryErrors);
      var secondaryValid = validators.isValid(secondaryErrors);
      this.props.onChange([primary, includeSecondary === true ? secondary : null], primaryValid && (includeSecondary === false|| secondaryValid));
    }
  }

  render() {
    return (
      <Fade in={true} onEntered={this.handleSlideEntered}>
        <div>
          <Row>
            <Col xs={12}>
              <Typography variant="h5" gutterBottom={true}>Tell us about yourself</Typography>
              <Typography gutterBottom={true}>
                The Retirement Income Security Evaluation (RISE&#x2122;) is designed
                to help answer one simple question, "How can my client&apos;s
                retirement security be improved through the addition of guaranteed
                income products in their portfolio?" To get started, complete the
                profile below, then click on "Next"
              </Typography>
            </Col>
          </Row>

          <Flex justifyContent="flex-end">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={this.state.includeSecondary}
                  onChange={(e) => this.handleIncludeSecondary(e.target.checked)}
                  className="includeSecondaryField"
                />
              }
              label="Include your Spouse"
            />
          </Flex>

          <Row>
            <Col xs={12} md={this.state.includeSecondary ? 6 : 12}>
              <IndividualProfileComponent
                title="Your Profile"
                value={this.state.primary}
                showErrors={this.props.showErrors}
                errors={this.state.primaryErrors}
                onChange={this.handlePrimaryChange}
                currentAgeRef={this.firstFieldRef}
                className="primaryProfile"
              />
            </Col>
            {this.state.includeSecondary === true &&
              <Col xs={12} md={6}>
                <IndividualProfileComponent
                  title="Your Spouse's Profile"
                  value={this.state.secondary}
                  showErrors={this.props.showErrors}
                  errors={this.state.secondaryErrors}
                  onChange={this.handleSecondaryChange}
                  className="secondaryProfile"
                />
              </Col>
            }
          </Row>
        </div>
      </Fade>
    );
  }
}
