import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col } from '../../Shared/Grid';
import { Section, Group, Heading, Display, Paragraph, HorizontalRule } from '../../Shared/Typography';
import { Button, Spinner } from '../../Shared/Controls';
import IncomeChart from './IncomeChart'
import config from '../../config';
import EarningsService from '../services/EarningsService';

export default function ResultsView({ value, onBack }) {
    const [results, setResults] = useState({ loading: true, error: false, data: null });

    function getBenefit() {
      setResults({ loading: true, error: false, data: null });

      const earnings = EarningsService.project(value.currentAge, value.retirementAge, value.claimAge, value.earnings);
      const salaries = {};
      earnings.forEach(e => salaries[e.age] = e.amount);

      const request = {
        currentAge: parseInt(value.currentAge),
        retirementAge: parseInt(value.retirementAge),
        claimingAge: parseInt(value.claimAge),
        salaries,
        benefitFrequency: 'Monthly'
      };

      fetch(`${config.API_URL}/rise/socialsecurity?output=Extended`, {
        method: 'POST',
        body: JSON.stringify(request),
        headers: { 'Content-Type': 'application/json' }
      })
      .then(r => r.json())
      .then(r => setResults({ loading: false, data: r, error: false }))
      .catch(e => setResults({ loading: false, data: null, error: true }));
    }

    const memoizedGetBenefit = useCallback(getBenefit, [value]);

    useEffect(() => {
      memoizedGetBenefit();
    }, [value, memoizedGetBenefit]);

    return (
      <Row>
        <Col style={{paddingLeft: 20, paddingRight: 20}}>

          <Section>
            <Heading>Social Security Benefit Calculator</Heading>
            <Paragraph>Your social security benefit is calculated from the highest 35 years of earnings, represented in the colored bars in the chart below.</Paragraph>
          </Section>

          <HorizontalRule />

          {results.loading === true &&
            <Section>
              <Row style={{justifyContent: 'center', alignItems: 'center', minHeight: 200}}>
                <Col xs="auto"><Spinner /></Col>
                <Col xs="auto"><Heading style={{marginBottom: '0.25rem'}}>Calculating...</Heading></Col>
              </Row>
            </Section>
          }

          {results.error === true &&
            <Section>
              <Row style={{alignItems: 'center', justifyContent: 'center', minHeight: 200}}>
                <Col xs="auto">
                  <Row style={{marginBottom: '1rem'}}>
                    <Col xs="auto"><Heading style={{marginBottom: 0, lineHeight: '2rem', fontSize: '3rem', color: '#FF113A'}}>&times;</Heading></Col>
                    <Col><Heading style={{marginBottom: 0, lineHeight: '2rem', textAlign: 'center'}}>Oops, something went wrong!</Heading></Col>
                  </Row>
                  <Row style={{justifyContent: 'center'}}>
                    <Col xs={5}><Button onClick={getBenefit} style={{width: '100%'}}>Try Again</Button></Col>
                  </Row>
                </Col>
              </Row>
            </Section>
          }

          {results.loading === false && results.error === false && results.data && results.data.salaries && results.data.salaries.length > 0 && <>
            <Section>
              <Heading level="2">Estimated benefit</Heading>

              <Row>
                <Col xs={12} sm="auto" style={{paddingRight: 20}}>
                  <Group>
                    <div>
                      <Display className="benefitAtRetirementMonthlyLabel" variant="thick" level={1}>${results.data.benefitAmount.toLocaleString()}</Display>
                      <Display level={2} weight="bold"> / monthly</Display>
                    </div>
                    <div className="benefitTodayMonthlyLabel">
                      (${results.data.benefitAmountDiscounted.toLocaleString()} in today's dollars)
                    </div>
                  </Group>
                </Col>
                <Col xs={12} sm="auto">
                  <Group>
                    <div>
                      <Display className="benefitAtRetirementAnnuallyLabel" variant="thick" level={1}>${(results.data.benefitAmount * 12).toLocaleString()}</Display>
                      <Display level={2} weight="bold"> / annually</Display>
                    </div>
                    <div className="benefitTodayAnnuallyLabel">
                      (${(results.data.benefitAmountDiscounted * 12).toLocaleString()} in today's dollars)
                    </div>
                  </Group>
                </Col>
                <Col xs={12}>
                  <Group>
                    <IncomeChart currentAge={value.currentAge} data={results.data.salaries}></IncomeChart>
                  </Group>
                </Col>
              </Row>
            </Section>
          </>}

          <HorizontalRule />

          <Section>
            <Row>
              <Col xs={5} sm={4} md={3} lg={2}>
                <Button className="backButton" variant="outline" onClick={onBack} style={{width: '100%'}}>Go Back</Button>
              </Col>
            </Row>
          </Section>

        </Col>
      </Row>
    );
}