import React, { useState, useRef } from 'react';
import { Popper } from 'react-popper';
import classes from './Controls.module.scss';

export function Button({ type = 'button', variant = 'solid', className, ...props }) {
  const btnType = variant === 'solid' ? 'btn' : 'btn-' + variant;
  return <button 
      {...props}
      type={type}
      className={`${classes[btnType]} ${className}`}
    >
      {props.children}
    </button>;
}

export function Link({ className, ...props }) {
  return <a 
      {...props}
      className={`${className}`}
    >
      {props.children}
    </a>;
}

export function TextField({ className, type = 'text', valid = null, ...props }) {
  const classNames = [classes.text];
  if (className) classNames.push(className);
  if (valid === true) classNames.push(classes['is-valid']);
  if (valid === false) classNames.push(classes['is-invalid']);
  
  return <input {...props} type={type} className={classNames.join(' ')} />;
}

export function Select({ className, valid = null, children, ...props }) {
  const classNames = [classes.select];
  if (valid === true) classNames.push(classes['is-valid']);
  if (valid === false) classNames.push(classes['is-invalid']);
  return (
    <select {...props} className={classNames.join(' ')}>
      {children}
    </select>
  );
}

export function Range(props) {
  return <input {...props} type="range" className={classes.range} />;
}

export function Tooltip({ className, content, children, ...props }) {
  const [show, setShow] = useState(false);
  const [showForAnimation, setShowForAnimation] = useState(false);
  const timeoutId = useRef(null);
  const triggerEl = useRef(null);

  function handleShow() {
    setShow(true);
    setShowForAnimation(true);
    if (timeoutId.current) clearTimeout(timeoutId.current);
  }

  function handleHide() {
    setShow(false);
    timeoutId.current = setTimeout(() => setShowForAnimation(false), 150);
  }

  var triggerClassNames = [classes['tooltip-trigger']];
  if (className)
    triggerClassNames.push(className);

  var tooltipClassNames = [classes.tooltip, classes.fade, classes['bs-tooltip-auto']];
  if (show === true)
    tooltipClassNames.push(classes.show);

  return <div className={classes['tooltip-container']}>
      <div
        {...props}
        ref={triggerEl}
        className={triggerClassNames.join(' ')}
        onMouseEnter={handleShow}
        onFocus={handleShow}
        onMouseLeave={handleHide}
        onBlur={handleHide}
        tabIndex="-1"
      >
        {children}
      </div>

      {showForAnimation === true &&
        <Popper placement="bottom" referenceElement={triggerEl.current}>
          {({ ref, style, placement, arrowProps }) => (
            <div
              ref={ref}
              className={tooltipClassNames.join(' ')}
              style={style}
              x-placement={placement}
            >
              <div className={classes.arrow} ref={arrowProps.ref} style={arrowProps.style}></div>
              <div className={classes['tooltip-inner']}>
                {content}
              </div>
          </div>)}
        </Popper>}
    </div>;
}

export function Spinner({ ...props }) {
  return <div {...props} className={classes['spinner-border']}></div>;
}
