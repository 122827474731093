const nawi = [1, 1.050073897, 1.075825348, 1.119791718, 1.139954389, 1.208380233, 1.275690278, 1.363368536, 1.442159559, 1.513727256, 1.589787509, 1.745588192, 1.854809189, 1.965067682, 2.111922403, 2.257651538, 2.392956768, 2.582982607, 2.808939111, 3.061951277, 3.370175885, 3.555711615, 3.728929519, 3.948132506, 4.116343999, 4.238521469, 4.508830957, 4.730896847, 4.918211493, 5.145391459, 5.337137488, 5.612127945, 5.660393564, 5.812313422, 6.045292603, 6.340940011, 6.710939718, 7.062181288, 7.455744893, 7.868047059, 8.055750766, 8.136540927, 8.335441768, 8.722936997, 9.042111599, 9.457714669, 9.886922648, 10.11436199, 9.961830399, 10.19727853, 10.51679325, 10.84518543, 10.98380135, 11.37368478, 11.76937966, 11.90237499, 12.31339496, 12.78376758, 13.32452114, 13.93078625, 14.56045865, 15.20548797, 15.87148744, 16.55872378, 17.2641261, 17.98921884, 18.70339095, 19.41412023, 20.15185624, 20.91762668, 21.71249596, 22.5375701, 23.39399671, 24.28296744, 25.20572042, 26.16353786, 27.15775333, 28.18974689, 29.26095734, 30.37287484, 31.52704343, 32.72507072, 33.96862307, 35.25943045, 36.59928892, 37.99006303, 39.43368585, 40.93216631, 42.48758919, 44.10211757, 45.77799773, 47.51756159, 49.32322916, 51.19751099, 53.14301549, 55.16244898, 57.25862297, 59.43445174, 61.6929597, 64.03729115, 66.47070785, 68.99659388, 71.61846548, 74.33996613, 77.16488367, 80.09715031, 83.14084262, 86.3001938, 89.57960096, 92.98362517, 96.51700369, 100.1846499, 103.9916658, 107.9433488, 112.0451972, 116.3029148, 120.7224256, 125.3098787];
const nawiStartYear = 1961;
const nawiMaxYear = nawiStartYear + nawi.length - 1;
const employmentStartAge = 20;

export default {

  project: (currentAge, retirementAge, claimingAge, providedEarnings) => {
    const currentYear = new Date().getFullYear();
    const maxRetirementAge = currentAge + (nawiMaxYear - currentYear);
    const adjustedRetirementAge = Math.min(Math.min(retirementAge, claimingAge), maxRetirementAge);
    const startAge = Math.min(employmentStartAge, currentAge);
    
    const providedAges = Object.keys(providedEarnings)
      .map(k => { return { age: parseInt(k), amount: providedEarnings[k] }; })
      .filter(i => i.amount > 0)
      .map(i => i.age)
      .sort();

    const earnings = [];
    for (var i = startAge; i <= adjustedRetirementAge; ++i) {
      // eslint-disable-next-line no-loop-func
      const previousAges = providedAges.filter(a => a < i);
      const pointAge = previousAges.length > 0 ? previousAges[previousAges.length - 1] : providedAges[0];
      const pointYear = currentYear - (currentAge - pointAge);
      const pointInflation = nawi[pointYear - nawiStartYear];
      const pointEarnings = providedEarnings[pointAge];

      const year = currentYear - (currentAge - i);
      const inflationFactor = nawi[year - nawiStartYear];

      earnings.push({
        age: i,
        amount: (providedEarnings[i] >= 0)
          ? providedEarnings[i]
          : pointEarnings * inflationFactor / pointInflation
      });
    }

    return earnings;
  }
};