import React from 'react';
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { Row, Col } from '../../Shared/Grid';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip} from 'recharts';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import constants from '../../constants';

const labels = {
  "_LargeCap": "U.S. Large Cap",
  "_MidCap": "U.S. Small/Mid Cap",
  "_International": "Developed Int'l Equities",
  "_Emerging": "Emerging Market Equities",
  "_Bonds": "U.S. Bonds & Cash",
  "_DIA": "Income Annuity",
  "_VA": "Variable Annuity",
  "_FIA": "Fixed Income Annuity"
};

export default class AssetAllocationComponent extends React.Component {
  getAllocations = () => {
    return Object.keys(this.props.allocations)
      .filter(key => Object.keys(labels).includes(key))
      .map(key => {
        return {
          key: key,
          label: labels[key],
          value: this.props.allocations[key]
        }
      });
  }

  render() {
    return <div style={{width: '100%'}}>
      <Row>
        <Col xs={12} md={6}>
          <Table>
            <TableBody>
              {this.getAllocations().map((a, index) =>
                <TableRow key={a.key} className={`${a.key}Asset`}>
                  <TableCell>
                    <FiberManualRecord style={{ fill: constants.colors[index % constants.colors.length] }} /> {a.label}
                  </TableCell>
                  <TableCell align="right" className="amountLabel">{a.value}%</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Col>

        <Col xs={12} md={6}>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                outerRadius="100%"
                nameKey="label"
                dataKey="value"
                data={this.getAllocations()}
                labelLine={false}
              >
                {
                  this.getAllocations().map((a, index) => <Cell key={`cell-${a.key}`} fill={constants.colors[index % constants.colors.length]} />)
                }
              </Pie>
              <Tooltip formatter={(value) => `${value}%`}/>
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </div>;
  }
}
