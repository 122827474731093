import React, { useState } from 'react';
import { Router, Redirect } from '@reach/router'
import { Container } from './Shared/Grid';
import Disclaimer from './Shared/Disclaimer';
import Footer from './Shared/Footer';

import AppBar from './Shared/AppBar';
import AppMenu from './Shared/AppMenu';
import logo from './logo.svg';

import RISEScore from './RISE/App';
import SocialSecurityBenefit from './SocialSecurityBenefit/App';

function AppContainer(props) {
  return (
    <Container
      style={{
        boxShadow: '0px 0px 3px #cccccc',
        backgroundColor: 'white',
        flex: 1,
        paddingTop: '3.625rem',
        paddingBottom: '3rem'
      }}
    >
      {props.children}
    </Container>
  );
}

function App() {
  const [menuOpened, setMenuOpened] = useState(false);

  return (
    <>
      <AppBar logo={logo}
        subbrand='THE RISE SCORE&trade;'
        onExpand={() => setMenuOpened(true)}
      />

      <AppMenu
        opened={menuOpened}
        onClose={() => setMenuOpened(false)}
        links={[
          {label: 'RISE Score', href:'/rise-score'},
          {label: 'Social Security Benefit', href:'/social-security-benefit'},
        ]}
      >
      </AppMenu>

      <Disclaimer />

      <AppContainer>
        <Router>
          <RISEScore path="/rise-score" />
          <SocialSecurityBenefit path="/social-security-benefit" />
          <Redirect from="/" to="/rise-score" noThrow={true} />
        </Router>
      </AppContainer>

      <Footer />
    </>
  );
}

export default App;
