import React from "react";
import { Typography } from '@material-ui/core';
import { Row, Col } from '../../Shared/Grid';
import { Button } from '../../Shared/Controls';
import { ResponsiveContainer, AreaChart, Area, Tooltip } from 'recharts';
import TimeBasedAssetLineItemComponent from "./TimeBasedAssetLineItemComponent";
import constants from '../../constants';

export default class AllocationsViewComponent extends React.Component {
  setValue(index, newValue) {
    if (this.props.onChange) {
      var allocations = [...this.props.value];
      if (newValue.timeSteps === 0)
        allocations.splice(index, 1);
      else
        allocations[index] = newValue;
      this.props.onChange(allocations);
    }
  }

  getChartData() {
    var data = [];

    for (var v = 0; v < this.props.value.length; ++v) {
      var value = this.props.value[v];
      var length = parseInt(value.timeSteps) >= 0
        ? value.timeSteps 
        : (data.length < 30 ? (30 - data.length) : 5);

      for (var y = 0; y < length; ++y)
      {
        var step = {};
        for (var i = 0; i < constants.savings.length; ++i) {
          var sid = constants.savings[i].id;
          step[sid] = value[sid];
        }
        data.push(step);
      }
    }

    return data;
  };

  renderTooltipContent = (o) => {
    var { payload, label } = o;
    
    return (
      <div style={{backgroundColor: "#fffffffa", padding: 8}}>
        <p className="total">{`Year ${label + 1}`}</p>
        {
          payload.map((entry, index) => (
            <div key={`item-${index}`}>
              <div style={{display: 'inline-block', width: 20, height: 20, marginRight: 8, backgroundColor: entry.fill, borderRadius: 2, verticalAlign: 'text-bottom'}}></div>
              {`${constants.savings.filter(s => s.id === entry.name)[0].label}: ${entry.value}%`}
            </div>
          ))
        }
      </div>
    );
  };

  handleAddPeriod = () => {
    var lastIndex = this.props.value.length - 1;
    var lastValue = this.props.value[lastIndex];     
    var newValue = JSON.parse(JSON.stringify(lastValue));
    newValue.timeSteps = 5;
    
    if (this.props.onChange) {
      var allocations = [...this.props.value];
      allocations.splice(allocations.length - 1, 0, newValue);
      this.props.onChange(allocations);
    }
  };

  render() {
    var value = this.props.value || [];
    var errors = this.props.errors || [];
    var chartData = this.getChartData();

    return (
      <div>
        <Typography style={{marginBottom: 24}}>
          The time-based allocation is designed to adjust allocations over the projection period.
          This type of allocation method can represent a glidepath or target date fund, which rebalances annually to the applicable allocation specified at that time.
          Please use the add period button or enter on your keyboard to add time periods.
          The time period can be adjusted on the left-hand side, but is defaulted to 5 year increments.
        </Typography>

        <Row style={{alignItems: "center"}}>
          <Col xs={12} md={6}>
            <table>
              <thead>
                <tr>
                  <th style={{verticalAlign: 'bottom'}}>Years</th>
                  {constants.savings.map((s, index) => 
                    <td key={`heading-${index}`} style={{height: 200, verticalAlign: 'bottom'}}>
                      <div style={{whiteSpace: 'nowrap', transform: 'rotate(-45deg)', transformOrigin: 'center', width: 10, margin: '0 auto', /*marginLeft: 'calc(100% - 32px)'*/}}>
                        <Typography style={{display: 'inline-block'}}>{s.label}</Typography>
                      </div>
                      <div style={{display: 'inline-block', width: '100%', height: 8, marginBottom: 4, backgroundColor: constants.colors[index % constants.colors.length], borderRadius: 2, verticalAlign: 'text-bottom'}}></div>
                    </td>
                  )}
                  <td></td>
                </tr>
              </thead>

              <tbody>
                {value.map((a, index) => 
                  <TimeBasedAssetLineItemComponent
                    key={`period-${index}`}
                    value={a}
                    isFirst={index === 0}
                    errors={errors[index] || {}}
                    showErrors={this.props.showErrors}
                    onChange={(a) => { this.setValue(index, a); }}
                    className={`period${index}`}
                    allocationRef={index === 0 ? this.props.firstFieldRef : null}
                  />
                )}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={2 + constants.colors.length}>
                    <Button variant="outline" style={{width: '100%', marginTop: 20}} onClick={this.handleAddPeriod} className="addPeriodButton">Add Period</Button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Col>

          <Col xs={12} md={6}>
            <ResponsiveContainer minHeight={300}>
              <AreaChart
                data={chartData}
                stackOffset="expand"
              >
                <Tooltip content={this.renderTooltipContent} />
                {constants.savings.map((s, index) =>
                  <Area
                    key={`area-${index}`}
                    type="monotone"
                    dataKey={s.id}
                    stackId="1"
                    stroke="transparent"
                    fillOpacity={1}
                    fill={constants.colors[index % constants.colors.length]}
                  />
                )}
              </AreaChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </div>
    );
  }
}