import React from "react";
import MediaQuery from "react-responsive";
import { Stepper, Step, StepLabel, MobileStepper, withStyles } from "@material-ui/core";
import { Row, Col } from '../../Shared/Grid';
import { Button } from '../../Shared/Controls';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

const styles = {
  stepPointer: {
    cursor: "pointer",
    '& .MuiStepLabel-root': {
      cursor: "pointer"
    }
  }
};

class StepperComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: props.activeStep || 0
    };
  }

  handleNext = () => {
    this.handleStep(this.state.activeStep + 1, this.state.activeStep);
  };

  handleBack = () => {
    this.handleStep(this.state.activeStep - 1, this.state.activeStep);
  };

  handleStep = (stepIndex, activeStepIndex) => {
    if (stepIndex !== activeStepIndex) {
      var step = this.props.steps[stepIndex];
      var prevStep = this.props.steps[stepIndex - 1];
      var activeStep = this.props.steps[activeStepIndex];
 
      if (activeStep.completed !== true) {
        if (this.props.onAttemptedStep) this.props.onAttemptedStep();
      }
      else if (typeof step.completed !== "undefined" || (prevStep && prevStep.completed === true)) {
        this.setState({ activeStep: stepIndex });
        if (this.props.onStep) this.props.onStep(stepIndex, stepIndex < activeStepIndex ? "right" : "left");
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Row>
          <Col xs={12} style={{paddingLeft: 20, paddingRight: 20, marginBottom: '1rem'}}>
            <MediaQuery query="(min-device-width: 1024px)">
              <Stepper activeStep={this.state.activeStep}>
                {this.props.steps.map((step, index) => {
                  var prevStep = this.props.steps[index - 1];
                  return (
                    <Step key={step.id} completed={step.completed === true}
                      className={step.completed === true || (prevStep && typeof prevStep.completed !== "undefined") ? classes.stepPointer : null}
                      onClick={() => this.handleStep(index, this.state.activeStep)}
                    >
                      <StepLabel error={this.props.showErrors === true && step.completed === false}>{step.label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </MediaQuery>
            <MediaQuery query="(max-device-width: 1024px)">
              <MobileStepper
                variant="progress"
                steps={5}
                position="static"
                activeStep={this.props.activeStep}
                nextButton={
                  <Button size="small" onClick={this.handleNext} disabled={this.props.activeStep > this.props.steps.length - 1}>
                    Next <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={this.handleBack} disabled={this.props.activeStep < 0}>
                    <KeyboardArrowLeft /> Back
                  </Button>
                }
              />
            </MediaQuery>
          </Col>
        </Row>
          
        <Row>
          <Col xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
            {this.props.children}
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={{paddingLeft: 20, paddingRight: 20, marginTop: '2rem'}}>
            <Row style={{justifyContent: 'space-between'}}>
              <Col xs={5} sm={4} md={3} lg={2}>
                {this.state.activeStep > 0 &&
                  <Button variant="outline" onClick={this.handleBack} className="backButton" style={{width: '100%'}}>Back</Button>}
              </Col>
              <Col xs={5} sm={4} md={3} lg={2}>
                {this.state.activeStep < this.props.steps.length - 1 &&
                  <Button type="submit" onClick={this.handleNext} className="nextButton" style={{width: '100%'}}>Next</Button>}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default withStyles(styles)(StepperComponent);