const barColors = {
    green: '#00A8AA', white: '#FFF', orange: '#FF6400', yellow: '#FFC800'
};

const barStyles = {
    excluded: {
        past: { stroke: barColors.white, fill: barColors.white },
        current: { stroke: barColors.white, fill: barColors.white },
        future: { stroke: barColors.white, fill: barColors.white }
    },
    included: {
        past: { stroke: barColors.yellow, fill: barColors.yellow },
        current: { stroke: barColors.orange, fill: barColors.orange },
        future: { stroke: barColors.green, fill: barColors.green }
    }
};

export default {
  formatBarData: (currentAge, earnings) => {
    return earnings.map((salary, index) => {
      const strokeType = salary.top35 === false ? 'excluded' : 'included';
      const offset = salary.age - currentAge;
      const fillType = offset > 0 ? 'future' : (offset < 0 ? 'past' : 'current');
      
      return {
          tick: index + 1,
          earnings: salary.amount,
          label: !isNaN(salary.amount) ? `Age ${salary.age}: ${salary.amount.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}` : '',
          svg: barStyles[strokeType][fillType]
      };
    });
  }
}
