import React from "react";
import { Fade, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import SimpleAllocationsComponent from "./SimpleAllocationsComponent";
import TimeBasedAllocationsComponent from "./TimeBasedAllocationsComponent";
import validators from '../../validators';

export default class AllocationsViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.firstFieldRef = React.createRef();

    var errors = this.triggerUpdate(props.value, false);
    this.state = {
      errors,
      simple: null,
      timeBased: null
    };
  }

  componentDidMount = () => {
    if (this.props.onViewInit)
      this.props.onViewInit();
  }

  handleSlideEntered = () => {
    if (this.firstFieldRef.current)
      this.firstFieldRef.current.focus();
  }

  isSimple = (props) => {
    return !Array.isArray((props || this.props).value);
  }

  handleChangeTab = (newTab) => {
    var isSimple = this.isSimple();
    var newValue = this.props.value;

    if (newTab === 0 && !isSimple) {
      newValue = this.state.simple || this.convertToSimple(this.props.value);
      this.setState({timeBased: this.props.value});
    }
    if (newTab === 1 && isSimple) {
      newValue = this.state.timeBased || this.convertToComplex(this.props.value);
      this.setState({simple: this.props.value});
    }

    this.triggerUpdate(newValue);
  }

  convertToSimple = (complex) => {
    var simple = {};
    for (var key of Object.keys(complex[0])) {
      if (key !== "timeSteps")
        simple[key] = complex[0][key];
    }
    return simple;
  }

  convertToComplex = (simple) => {
    var first = {}
    for (var key of Object.keys(simple)) {
      first[key] = simple[key];
    }
    first.timeSteps = null;
    return [first];
  }

  setValue = (value) => {
    this.triggerUpdate(value);
  }

  triggerUpdate = (value, setState = true) => {
    var errors = validators.validateAllocations(value);
    
    if (!!setState) this.setState({ errors });
    
    var isValid = (Array.isArray(errors) ? errors : [errors])
      .reduce((all, current) => 
        all && Object.keys(current).length === 0
      , true);

    if (this.props.onChange) this.props.onChange(value, isValid);
    
    return errors;
  }

  render() {
    var value = this.props.value || {};
    var errors = this.state.errors || {};
    var tab = this.isSimple() ? 0 : 1;

    return (
      <Fade in={true} onEntered={this.handleSlideEntered}>
        <div>
          <Typography variant="h5" gutterBottom={true}>Estimate your Savings Allocations</Typography>
          <Typography gutterBottom={true}>
            Your current portfolio allocations can be entered on this screen.
            These allocations can be entered traditionally at a single point in time or as time-based allocation.
          </Typography>

          <AppBar position="static" color="default" style={{marginTop: 24, marginBottom: 16}}>
            <Tabs value={tab} onChange={(e, v) => this.handleChangeTab(v)}>
              <Tab label="Simple Allocation" />
              <Tab label="Time-Based Allocation" />
            </Tabs>
          </AppBar>

          {tab === 0 ?
            <SimpleAllocationsComponent
              value={value}
              errors={errors}
              showErrors={this.props.showErrors}
              onChange={value => this.setValue(value)}
              firstFieldRef={this.firstFieldRef}
            />
          : null}

          {tab === 1 ?
            <TimeBasedAllocationsComponent
              value={value}
              errors={errors}
              showErrors={this.props.showErrors}
              onChange={value => this.setValue(value)}
              firstFieldRef={this.firstFieldRef}
            />
          : null}
        </div>
      </Fade>
    );
  }
}