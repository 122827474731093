import React from 'react';
import { RadioGroup, Radio, FormControlLabel, Typography, withStyles, Slider } from "@material-ui/core";
import { Row, Col } from '../../Shared/Grid';
import { ResponsiveContainer, ComposedChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import LoadingProgressComponent from '../Shared/LoadingProgressComponent';
import api from "../../api/Api";

const styles = {
  horizontal: {
    flexDirection: "row"
  },
  label: {
    marginBottom: 0
  }
};

const labels = {
  "dia": "Income Annuity",
  "va": "Variable Annuity",
  "fia": "Fixed Income Annuity",
  "savings": "Savings",
  "inflation": "Inflation",
  "needs": "Total Expenses",
  "accountValue": "Account Value",
  "bondReturns": "Bond Returns",
  "equityReturns": "Equity Returns",
  "supportability": "Supportability",
  "other": "Other"
}

const axes = {
  "dia": "dollars",
  "va": "dollars",
  "fia": "dollars",
  "savings": "dollars",
  "inflation": "percent",
  "needs": "dollars",
  "accountValue": "dollars",
  "bondReturns": "percent",
  "equityReturns": "percent",
  "supportability": "percent",
  "other": "dollars"
}

const types = { 
  "dia": "area",
  "va": "area",
  "fia": "area",
  "savings": "area",
  "inflation": "line",
  "needs": "area",
  "accountValue": "area",
  "bondReturns": "line",
  "equityReturns": "line",
  "supportability": "area",
  "other": "area"
}

const stackId = {
  "dia": "stack1",
  "va": "stack1",
  "fia": "stack1",
  "savings": "stack1",
  "inflation": null,
  "needs": "stack2",
  "accountValue": "stack3",
  "bondReturns": null,
  "equityReturns": null,
  "supportability": null,
  "other": "stack1"
}

const colors = {
  "dia": '#FF6400',
  "va": '#FFC800',
  "fia": '#74BF60',
  "savings": '#00A8AA',
  "other": '#005C98',
  "needs": '#C22248',
  "accountValue": '#74BF60',
  "bondReturns": '#00A8AA',
  "equityReturns": '#005C98',
  "supportability": '#C22248',
  "inflation": '#7C4C97'
}

class AnalyticsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "income",
      scenario: 1,
      data: null,
      lines: [],
      loading: false,
      error: false
    };
  }

  componentDidMount() {
    this.updateChart();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeScore !== this.props.activeScore || prevProps.scoreId !== this.props.scoreId)
      this.updateChart();
  }

  updateChart = () => {
    this.setState({ loading: true });

    var type = this.state.type;
    var scenario = this.state.scenario;

    api
      .getSpecificChart(this.props.scoreId, this.props.activeScore, scenario, type)
      .then(r => {
        var data = r.data;
        var keys = Object.keys(data);
        var length = Object.values(data)[0].length;
        var flipperFn = key => item[key] = data[key][i];
        var flippedData = [];
        
        for (var i = 0; i < length; ++i)
        {
          var item = { name: i };
          keys.forEach(flipperFn);
          flippedData.push(item);
        }

        this.setState({
          data: flippedData,
          lines: Object.keys(data),
          loading: false,
          error: false,
          type,
          scenario
        });
      })
      .catch(e => {
        this.setState({
          data: null,
          lines: [],
          loading: false,
          error: true
        });
      });
  };

  render() {
    const { classes } = this.props;
    return <div style={{width: '100%'}}>
      <Row style={{alignItems: 'center'}}>
        <Col xs="auto" style={{marginBottom: 20}}>
          <RadioGroup
            value={this.state.type}
            onChange={(e, v) => { this.setState({type: v}, this.updateChart); }}
            className={classes.horizontal}
            justify="center"
          >
            <FormControlLabel value="income" control={<Radio margin="normal" />} label="Income Sources" className={`${classes.label} incomeField`} margin="normal" />
            <FormControlLabel value="expenses" control={<Radio margin="normal" />} label="Expenses" className={`${classes.label} expensesField`} margin="normal" />
            <FormControlLabel value="returns" control={<Radio margin="normal" />} label="Returns" className={`${classes.label} returnsField`} margin="normal" />
            <FormControlLabel value="supportability" control={<Radio margin="normal" />} label="Supportability" className={`${classes.label} supportabilityField`} margin="normal" />
          </RadioGroup>
        </Col>
        <Col xs={12} lg style={{marginBottom: 20}}>
          <Row>
            <Col xs="auto"><Typography>Best</Typography></Col>
            <Col xs>
              <Slider min={1} max={1000}
                value={this.state.scenario}
                valueLabelDisplay="auto"
                onChange={(e, v) => this.setState({scenario: v})}
                onChangeCommitted={(e, v) => this.setState({ scenario: v }, this.updateChart)}
                className="scenarioField"
              />
            </Col>
            <Col xs="auto"><Typography>Worst</Typography></Col>
          </Row>
        </Col>
      </Row>

      <div>
        {this.state.loading === true ?
          <LoadingProgressComponent height={400} label="Loading Analytics..." /> :
          this.state.data != null && this.state.lines.length > 0 ?
            <ResponsiveContainer height={400}>
              <ComposedChart data={this.state.data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" type="number" domain={['dataMin', 'dataMax']} label="Years" />
                <YAxis yAxisId="dollars" tickFormatter={(value) => `$${value}`} />
                <YAxis yAxisId="percent" orientation="right" tickFormatter={(value) => `${value}%`} />
                <Tooltip
                  labelFormatter={(value) => `Year ${value}`}
                  formatter={(value, n, props) => `${axes[props.dataKey] === "dollars" ? "$" : ""}${Math.round(value * 100) / 100}${axes[props.dataKey] === "percent" ? "%" : ""}`}
                />
                <Legend />
                {this.state.lines.map(key =>
                  (types[key] === 'area')
                    ? <Area key={`area-${key}`}
                        fillOpacity={0.8}
                        dataKey={key}
                        name={labels[key]}
                        stackId={stackId[key]}
                        stroke={colors[key]}
                        fill={colors[key]}
                        yAxisId={axes[key]}
                        dot={false}
                        legendType="square"
                      />
                    : <Line key={`line-${key}`}
                        dataKey={key}
                        name={labels[key]}
                        stroke={colors[key]}
                        yAxisId={axes[key]}
                        dot={false}
                        legendType="square"
                      />
                )}
              </ComposedChart>
            </ResponsiveContainer>
          : null
        }
      </div>
    </div>
  }
}

export default withStyles(styles)(AnalyticsComponent);