import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import StepperComponent from "./Shared/StepperComponent";
import ProfilesViewComponent from "./Profiles/ProfilesViewComponent";
import SavingsViewComponent from "./Savings/SavingsViewComponent";
import AllocationsViewComponent from "./Allocations/AllocationsViewComponent";
import IncomeViewComponent from "./Income/IncomeViewComponent";
import ExpensesViewComponent from "./Expenses/ExpensesViewComponent";
import SummaryViewComponent from "./Summary/SummaryViewComponent";

const theme = createMuiTheme({
  palette: {
    primary: { main: '#ff6400' },
    secondary: { main: '#ff6400' }
  }
});

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.stepperRef = React.createRef();

    this.state = {
      steps: [
        { id: "profiles", label: "Profile" },
        { id: "savings", label: "Savings" },
        { id: "allocations", label: "Allocations" },
        { id: "income", label: "Income" },
        { id: "expenses", label: "Expenses" },
        { id: "strategies", label: "Summary" }
      ],
      activeStep: 0,
      showErrors: false,

      profiles: [{
        currentAge: 60,
        retirementAge: 67,
        gender: "Male",
        health: "Average"
      }],
      savings: {
        amount: 1000000,
        contributions: 15000,
        inflationType: "scenario"
      },
      allocations: {
        largeCap: 70,
        bonds: 30
      },
      income: {
        cashflows: {
          socialSecurity: { amount: 20000, inflationType: "scenario", dollarType: "current" }
        },
        effectiveTaxRate: 20
      },
      expenses: {
        basicLiving: { amount: 55000, inflationType: "scenario", dollarType: "current" },
        medical: { amount: 5000, inflationType: "scenario", dollarType: "current" },
        other: { inflationType: "scenario", dollarType: "current" }
      },
      strategies: [ {}, null, null, null ]
    };
  }

  handleStep = (step, direction) => {
    this.setState({
      activeStep: step,
      showErrors: false
    });
  }

  handleAttemptedStep = () => {
    this.setState({ showErrors: true });
  }

  handleViewUpdate = (key, data, isValid) => {
    var newState = {};
    if (data) newState[key] = data;

    // if the retirement age changes, make sure annuties are capped to that age
    if (key === "profiles" && this.state.profiles[0].retirementAge !== data[0].retirementAge) {
      newState.strategies = this.state.strategies.map(s => {
        if (s && s.type) s.withdrawalAge = Math.max(s.withdrawalAge, data[0].retirementAge);
        return s;
      });
    }

    var newSteps = this.state.steps;
    newSteps.filter(s => s.id === key)[0].completed = isValid;
    newState.steps = newSteps;

    this.setState(newState);
  }

  getRequest = () => {
    return {
      profiles: this.state.profiles,
      savings: this.state.savings,
      allocations: this.state.allocations,
      income: this.state.income,
      expenses: this.state.expenses,
      strategies: this.state.strategies
    };
  }

  render() {
    var stepComponent;
    switch (this.state.activeStep) {
      case 0:
        stepComponent =
          <ProfilesViewComponent
            value={this.state.profiles}
            showErrors={this.state.showErrors}
            onChange={(value, isValid) => this.handleViewUpdate("profiles", value, isValid)}
          />;
        break;

      case 1:
        stepComponent = 
          <SavingsViewComponent
            value={this.state.savings}
            showErrors={this.state.showErrors}
            onChange={(value, isValid) => this.handleViewUpdate("savings", value, isValid)}
          />;
        break;

        case 2:
          stepComponent = 
            <AllocationsViewComponent
              value={this.state.allocations}
              showErrors={this.state.showErrors}
              onChange={(value, isValid) => this.handleViewUpdate("allocations", value, isValid)}
            />;
          break;

      case 3:
        stepComponent =
          <IncomeViewComponent
            value={this.state.income}
            showErrors={this.state.showErrors}
            onChange={(value, isValid) => this.handleViewUpdate("income", value, isValid)}
          />;
        break;

      case 4:
        stepComponent =
          <ExpensesViewComponent
            value={this.state.expenses}
            showErrors={this.state.showErrors}
            onChange={(value, isValid) => this.handleViewUpdate("expenses", value, isValid)}
          />;
        break;

      case 5:
        stepComponent =
          <SummaryViewComponent
            value={this.getRequest()}
            onChange={(value, isValid) => this.handleViewUpdate("strategies", value, isValid)}
          />;
        break;

      default:
        stepComponent = <></>;
        break;
    } 

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        
        <form onSubmit={(e) => e.preventDefault()} noValidate>
          <StepperComponent
            steps={this.state.steps}
            showErrors={this.state.showErrors}
            onAttemptedStep={this.handleAttemptedStep}
            onStep={this.handleStep}
            ref={this.stepperRef}
          >
            {stepComponent}
          </StepperComponent>
        </form>
      </MuiThemeProvider>
    );
  }
}
