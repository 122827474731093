export default {

  isValid(errors) {
    var keys = Object.keys(errors);
    for (var i = 0; i < keys.length; ++i)
    {
      var value = errors[keys[i]];
      if (typeof value === "boolean" && value === true)
        return false;
      else if (typeof value === "object") {
        var isChildValid = this.isValid(value);
        if (isChildValid === false)
          return false;
      }
    }
    return true;
  },

  isNumber(value) {
    var number = Number(value);
    return !isNaN(number);
  },

  isInt(value) {
    var isNumber = this.isNumber(value);
    return isNumber && value % 1 === 0;
  },

  validateProfile(profile) {
    var errors = {};

    if (!profile.currentAge || !this.isInt(profile.currentAge) || profile.currentAge < 25 || profile.currentAge > 85)
      errors.currentAge = true;
    
    if (!profile.retirementAge || !this.isInt(profile.retirementAge) || profile.retirementAge < 50 || profile.retirementAge > 85 || profile.retirementAge < profile.currentAge)
      errors.retirementAge = true;

    return errors;
  },

  validateSavings(savings) {
    var errors = {};
    if (savings.amount === '' || isNaN(savings.amount) || +savings.amount <= 0)
      errors.amount = true;

    if (savings.contributions && (!this.isNumber(savings.contributions) || savings.contributions < 0))
      errors.contributions = true;

    if (savings.inflationType === "rate" && (!savings.inflationRate || !this.isNumber(savings.inflationRate) || savings.inflationRate < 0 || savings.inflationRate > 100))
      errors.inflationRate = true;

    return errors;
  },

  validateAllocations(allocations) {
    if (Array.isArray(allocations)) {
      var errors = [];
      for (var i = 0; i < allocations.length; ++i) {
        errors.push(this.validateAllocations(allocations[i]));
      }
      return errors;
    }
    else
      return this.validateAllocation(allocations);
  },

  validateAllocation(allocations) {
    var errors = {};

    if (typeof(allocations.timeSteps) === "number" && isNaN(allocations.timeSteps))
      errors.timeSteps = true;

    var totalSum = (allocations.largeCap || 0)
      + (allocations.smallMidCap || 0)
      + (allocations.developedIntEquities || 0)
      + (allocations.emergingMarketEquities || 0)
      + (allocations.bonds || 0);

    if (totalSum !== 100)
      errors.allocation = true;

    return errors;
  },

  validateIncome(income) {
    var errors = {};

    Object.keys((income || {}).cashflows || {}).forEach(k => {
      if (!errors.cashflows) errors.cashflows = {};
      errors.cashflows[k] = this.validateCashFlowLineItem(income.cashflows[k] || {});
    });

    if (!income.effectiveTaxRate || !this.isNumber(income.effectiveTaxRate) || income.effectiveTaxRate < 0 || income.effectiveTaxRate > 100)
      errors.effectiveTaxRate = true;

    return errors;
  },

  validateExpenses(expenses) {
    var errors = {};
    expenses = expenses || {};
    
    Object.keys(expenses).forEach(k => {
      errors[k] = this.validateCashFlowLineItem(expenses[k] || {});
    });

    var totalExpenses = Object.values(expenses)
      .reduce((total, expense) => total + (parseFloat(expense.amount) || 0), 0);
    if (Object.keys(expenses).length === 0 || !(totalExpenses > 0))
      errors.general = { required: true };

    return errors;
  },

  validateCashFlowLineItem(lineItem) {
    var errors = {};

    if (lineItem.amount && (!this.isNumber(lineItem.amount) || lineItem.amount < 0))
      errors.amount = true;

    if (lineItem.inflationType === "rate" && (!lineItem.inflationRate || !this.isNumber(lineItem.inflationRate) || lineItem.inflationRate < 0 || lineItem.inflationRate > 100))
      errors.inflationRate = true;
      
    return errors;
  },

  validateStrategy(strategy, retirementAge) {
    var errors = {};

    if (['DIA', 'VA', 'FIA'].indexOf(strategy.type) < 0)
      errors.type = true;

    if (!strategy.allocation || !this.isNumber(strategy.allocation) || strategy.allocation < 1 || strategy.allocation > 100)
      errors.allocation = true;

    if (strategy.withdrawalAge && ((!this.isInt(strategy.withdrawalAge)) || parseInt(strategy.withdrawalAge) < parseInt(retirementAge)))
      errors.withdrawalAge = true;

    return errors;
  }
}
