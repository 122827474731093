import React from 'react';
import classes from './Icons.module.scss';

export function Hamburger({ className = '', color, size = 24, ...props }) {
  color = color || '#000';
  const style = {
    height: size,
    width: size,
    ...props.style
  };

  return (
    <svg
      className={`${classes.icon} ${className}`}
      viewBox="0 0 24 24"
      style={style}
      {...props}
    >
      <path fill="none" stroke={color} strokeWidth="2" d="M2,19 L22,19 M2,5 L22,5 M2,12 L22,12"></path>
    </svg>
  );
}

export function StatusGood({ className = '', color, size = 24, ...props }) {
  color = color || '#000';
  const style = {
    height: size,
    width: size,
    ...props.style
  };

  return (
    <svg
      className={`${classes.icon} ${className}`}
      viewBox="0 0 24 24"
      style={style}
      {...props}
    >
      <path fill="none" stroke={color} strokeWidth="2" d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M7,12 L11,15 L16,8"></path>
    </svg>
  );
}


export function StatusBad({ className = '', color, size = 24, ...props }) {
  color = color || '#000';
  const style = {
    height: size,
    width: size,
    ...props.style
  };

  return (
    <svg
      className={`${classes.icon} ${className}`}
      viewBox="0 0 24 24"
      style={style}
      {...props}
    >
      <path fill="none" stroke={color} strokeWidth="2" d="M12.7031806,2.70318058 C12.3148247,2.31482467 11.6897542,2.31024575 11.2968194,2.70318058 L2.70318058,11.2968194 C2.31482467,11.6851753 2.31024575,12.3102458 2.70318058,12.7031806 L11.2968194,21.2968194 C11.6851753,21.6851753 12.3102458,21.6897542 12.7031806,21.2968194 L21.2968194,12.7031806 C21.6851753,12.3148247 21.6897542,11.6897542 21.2968194,11.2968194 L12.7031806,2.70318058 Z M8.98264552,14.7001725 L14.7001725,8.98264552 M8.98264552,8.98264552 L14.7001725,14.7001725"></path>
    </svg>
  );
}


export function Info({ className = '', color, size = 1, ...props }) {
  color = color || '#000';
  // const style = {
  //   height: `${size}rem`,
  //   width: `${size}rem`,
  //   ...props.style
  // };

  return (
    <svg
      className={`${classes.icon} ${className}`}
      viewBox="0 0 24 24"
      // style={style}
      {...props}
    >
      <path fill="none" stroke={color} strokeWidth="2" d="M12,22 C17.5228475,22 22,17.5228475 22,12 C22,6.4771525 17.5228475,2 12,2 C6.4771525,2 2,6.4771525 2,12 C2,17.5228475 6.4771525,22 12,22 Z M12,10 L12,18 M12,6 L12,8"></path>
    </svg>
  );
}
