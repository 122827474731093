import React from "react";
import { Typography, TextField, InputAdornment } from '@material-ui/core';
import { Row, Col } from '../../Shared/Grid';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';
import SimpleAssetLineItemComponent from "./SimpleAssetLineItemComponent";
import constants from '../../constants';

export default class SimpleAllocationsComponent extends React.Component {
  setValue(newValue) {
    var allocations = { ...this.props.value, ...newValue };
    if (this.props.onChange) this.props.onChange(allocations);
  }

  getChartData() {
    return constants.savings.map(s => {
      return { name: s.label, value: (this.props.value || {})[s.id] }
    });
  };

  render() {
    var value = this.props.value || {};
    var errors = this.props.errors || {};
    var chartData = this.getChartData();

    return (
      <div>
        <Typography style={{marginBottom: 24}}>
          The simple allocation is a traditional allocation method which assumes the allocation entered is held for the entire projection period and rebalanced annually in our model.
        </Typography>

        <Row>
          <Col xs={12} sm={6}>
            <Row>
              {constants.savings.map((s, index) =>
                <Col xs={12} key={`asset-${s.id}`}>
                  <SimpleAssetLineItemComponent
                    color={constants.colors[index % constants.colors.length]}
                    label={s.label}
                    value={value[s.id]}
                    onChange={(e, v) => {
                      var obj = {};
                      obj[s.id] = v;
                      this.setValue(obj);
                    }}
                    className={`${s.id}Asset`}
                    allocationRef={index === 0 ? this.props.firstFieldRef : null}
                  />
                </Col>
              )}
              
              <Col xs={12}>
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    inputProps: { style: { textAlign: 'right' } }
                  }}
                  value={chartData.reduce((total, assetType) => total + (assetType.value || 0), 0)}
                  error={this.props.showErrors ? errors.allocation : null}
                  readOnly={true}
                  className="totalField"
                  style={{float: "right"}}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={12} sm={6}>
            <ResponsiveContainer minHeight={300}>
              <PieChart>
                <defs>
                  {constants.colors.map((c, index) =>
                    <linearGradient key={`color-${index}`} id={`color${index}`} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={c} stopOpacity={0.9}/>
                      <stop offset="95%" stopColor={c} stopOpacity={0.9}/>
                    </linearGradient>
                  )}
                </defs>
                <Pie dataKey="value" data={chartData} labelLine={false} outerRadius="90%">
                  {
                    (chartData || []).map((entry, index) => <Cell key={index} fill={`url(#color${index % constants.colors.length})`} stroke={null} />)
                  }
                </Pie>
                <Tooltip formatter={(value) => { return value + '%' }} />
              </PieChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </div>
    );
  }
}