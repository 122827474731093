import React, { useEffect, useState, useRef } from 'react';
import { VictoryBar, VictoryChart, VictoryAxis, VictoryLabel, VictoryTooltip } from 'victory';
import CalculatorService from '../services/CalculatorService';

export default function IncomeChart(props) {
  const data = CalculatorService.formatBarData(props.currentAge, props.data);
  const containerRef = useRef(null);
  const [width, setWidth] = useState(500);

  useEffect(() => {
    const handleResize = () => setWidth(containerRef.current.clientWidth);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function tickLabelSwitch({ ticks, index }, start, middle, end) {
    if (index === 0) return start;
    else if (index === ticks.length - 1) return end;
    return middle;
  }

  function isSmall() {
    return width < 680;
  }

  function isMedium() {
    return width < 920;
  }

  return <div ref={containerRef}>
    <svg className="earningsChart" viewBox={`0 0 ${width} ${isMedium() ? 300 : 500}`} style={{background: '#F5F5F5'}}>
      <VictoryChart
        domainPadding={{ x: 10 }}
        padding={{top: 20, right: 20, bottom: 30, left: 20}}
        animate={{ duration: 500 }}
        width={width}
        height={isMedium() ? 300 : 500}
        standalone={false}
      >

        <VictoryAxis
          tickCount={data.length}
          tickLabelComponent={
            <VictoryLabel
              dy={-5}
              dx={tick => tickLabelSwitch(tick, -5, 0, 5)}
              textAnchor={tick => tickLabelSwitch(tick, 'start', 'middle', 'end')}
            />
          }
          tickFormat={(datum, index, ticks) => {
              if (index === 0)
                return 'Historical' + (!isSmall() ? ' Earnings' : '');
              else if (props.data[index] && props.data[index].age - props.currentAge === 0)
                return 'Current' + (!isSmall() ? ' Earnings' : '');
              else if (index === ticks.length - 1)
                return 'Future' + (!isSmall() ? ' Earnings' : '');
              return '';
          }}
          style={{
            tickLabels: {
              fontFamily: 'Arial',
              fontSize: () => isMedium() ? 10 : 14,
              fontWeight: 'bold'
            }
          }}
        />

        <VictoryAxis
          dependentAxis
          tickFormat={(x) => (`$${x / 1000}k`)}
          tickLabelComponent={<VictoryLabel dy={-5} dx={10} textAnchor="start" verticalAnchor="end" />}
          style={{
            axis: { stroke: 'none' },
            tickLabels: {
              fontFamily: 'Arial',
              fontSize: 14,
              fontWeight: 'bold',
              fill: '#39414D'
            },
            grid: { stroke: '#D9D9D9' }
          }}
        />

        <VictoryBar
          barRatio={0.9}
          style={{
            data: {
              fill: ({ datum }) => datum.svg.fill,
              stroke: ({ datum }) => datum.svg.stroke,
              strokeOpacity: 0.7,
              strokeWidth: 0,
              cursor: props.onClick ? 'pointer' : 'inherit'
            }
          }}
          data={data}
          x="tick"
          y="earnings"
          labelComponent={<VictoryTooltip style={{ fontSize: 14 }} cornerRadius={0} dy={-5} constrainToVisibleArea renderInPortal={false} />}
          events={[{
            target: 'data',
            eventHandlers: {
              onClick: props.onClick
                ? (evt, { datum, index }) => {
                  props.onClick(datum, index);
                }
              : () => {}
            }
          }]}
        />

      </VictoryChart>
    </svg>
  </div>;
}