import React from 'react';
import { Typography } from "@material-ui/core";
import { Row, Col } from '../../Shared/Grid';

export default class OverviewComponent extends React.Component {
  render() {
    return <Row style={{alignItems: 'center'}}>
    <Col xs={12} md={4} style={{paddingTop: 40, paddingBottom: 40}}>
      <Typography variant="h2" align="center" className="scoreLabel">
        {this.props.score}
      </Typography>
      <Typography variant="subtitle2" align="center" gutterBottom={true}>
        (out of 850)
      </Typography>
    </Col>

    <Col xs={12} md={8}>
      <Typography variant="h5" gutterBottom={true}>RISE Score&trade; Explained</Typography>
      <Typography gutterBottom={true}>
        Your RISE of {this.props.score} represents your retirement security given your current portfolio without any lifetime income solutions.
      </Typography>
      <Typography gutterBottom={true}>
        Your RISE of {this.props.score} is based on your client's current portfolio, projected savings' contributions, income sources, and expenses, without inclusion of any guaranteed income products. Your client's overall savings portfolio is invested in 0% Equity/Growth and 0% Fixed Income Securities, based on your previous entries. An annual fund fee of 120bps has been assumed on that portfolio, which may differ from your client's actual portfolio fund fees. Please refer to our methodology and assumptions documentation for additional information regarding the RISE Score calculation, as well as capital market assumptions, mortality assumption, design of annuity products, and other details.
      </Typography>
    </Col>
  </Row>;
  }
}
