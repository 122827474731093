import React, { useState, useEffect } from 'react';
import {
  DialogContentText,
  TextField, InputLabel, InputAdornment, 
  FormControl, Select, MenuItem
} from "@material-ui/core";
import { Row, Col } from '../../Shared/Grid';
import { Button } from '../../Shared/Controls';
import Modal from '../../Shared/Modal';
import validators from "../../validators";

export default function StrategyFormComponent(props) {
  const [type, setType] = useState(null);
  const [allocation, setAllocation] = useState(null);
  const [withdrawalAge, setWithdrawalAge] = useState(null);
  const [fundingStrategy, setFundingStrategy] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setType((props.value || {}).type || "DIA");
    setAllocation((props.value || {}).allocation || "10");
    setWithdrawalAge((props.value || {}).withdrawalAge || `${props.retirementAge}`);
    setFundingStrategy((props.value || {}).fundingStrategy || "Ordered");
    setErrors(null);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const handleSave = () => {
    var strategy = { type, allocation, withdrawalAge, fundingStrategy };
    var errors = validators.validateStrategy(strategy, props.retirementAge);

    if (!!validators.isValid(errors)) {
      props.onSave(strategy);
      props.onClose();
    }
    else
      setErrors(errors);
  }

  return (
    <Modal
      onClose={props.onClose}
      open={props.open}
      size="lg"
      scrollable={true}
      title={`${props.value ? 'Edit' : 'Add an'} Annuity`}
      actions={
        <Row style={{justifyContent: 'space-between'}}>
          <Col xs={5} md={4} lg={3}>
            <Button variant="outline" onClick={props.onClose} style={{width: '100%'}}>
              Cancel
            </Button>
          </Col>
          <Col xs={5} md={4} lg={3}>
            <Button onClick={handleSave} style={{width: '100%'}}>
              {props.value ? "Apply" : "Add"}
            </Button>
          </Col>
        </Row>
      }>

      <DialogContentText>
        The purpose of the RISE Score&trade; is to help you demonstrate how the inclusion of lifetime income solutions may improve your client's retirement income security.
        Below, the RISE Score&trade; includes three lifetime income product types as additional strategies for comparison to your client's current retirement planning strategy.
        These three strategies are an Income Annuity (similar to a Single Premium Annuity or an Immediate Annuity), a Variable Annuity with a Guaranteed Lifetime Withdrawal Benefit, and a Fixed-Index Annuity with a Guaranteed Lifetime Withdrawal Benefit. Please enter an allocation to each of these product types and select a withdrawal method ("Bonds First" or "Proportional"). 
      </DialogContentText>
      <DialogContentText>
        Each strategy is independent and only a single allocation to each annuity product type will be applied in the results on the next screen.
        These are hypothetical allocations to provide indicative examples and are solely for information purposes.
        They are not a recommendation of these or other annuity products for a particular investor who uses the RISE Score&trade;.
      </DialogContentText>

      <FormControl fullWidth margin="normal">
        <InputLabel>Type of Annuity</InputLabel>
        <Select value={type || 'DIA'} onChange={(e) => setType(e.target.value)} className="annuityTypeField">
          <MenuItem value="DIA">Income Annuity</MenuItem>
          <MenuItem value="VA">Variable Annuity</MenuItem>
          <MenuItem value="FIA">Fixed Income Annuity</MenuItem>
        </Select>
      </FormControl>

      <TextField
        inputRef={props.allocationRef}
        InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }}
        label="Purchased Amount Percentage"
        value={allocation || ""}
        error={(errors || {}).allocation}
        onChange={(e) => setAllocation(e.target.value)}
        fullWidth
        margin="normal"
        className="allocationField"
      />

      <TextField
        label="Payment Age"
        value={withdrawalAge || ""}
        error={(errors || {}).withdrawalAge}
        onChange={(e) => setWithdrawalAge(e.target.value)}
        fullWidth
        margin="normal"
        className="paymentAgeField"
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Funding Strategy</InputLabel>
        <Select value={fundingStrategy || 'Ordered'} onChange={(e) => setFundingStrategy(e.target.value)} className="fundingStrategyField">
          <MenuItem value="Ordered">Bonds First</MenuItem>
          <MenuItem value="Proportional">Proportional</MenuItem>
        </Select>
      </FormControl>
    </Modal>
  );
};
