import React, { useState, useEffect } from "react";
import { Fade, Typography, Box, Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import { Row, Col } from '../../Shared/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScoreComponent from "./ScoreComponent";
import AddStrategyComponent from "./AddStrategyComponent";
import StrategyFormComponent from "./StrategyFormComponent";
import OverviewComponent from "./OverviewComponent";
import AssetAllocationComponent from "./AssetAllocationComponent";
import StatisticsComponent from "./StatisticsComponent";
import AnalyticsComponent from "./AnalyticsComponent";
import LoadingProgressComponent from "../Shared/LoadingProgressComponent";
import constants from "../../constants";
import api from "../../api/Api";

export default function SummaryComponent(props) {

  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [scores, setScores] = useState([null, null, null, null]);
  const [activeScore, setActiveScore] = useState(-1);
  const [editStrategy, setEditStrategy] = useState(null);
  const [openStrategyForm, setOpenStrategyForm] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (props.onViewInit) props.onViewInit(); }, []);

  useEffect(() => {
    var strategies = props.value.strategies.map((s, index) => {
      return (!scores[index] || scores[index].strategy !== s) ? s : null;
    });
    
    calculateScore(strategies);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value.strategies]);

  function calculateScore(strategies) {
    if (strategies.filter(s => s !== null).length === 0)
      return;

    setError(false);

    // update scores
    var indexes = strategies.map((s, index) => s ? index : null).filter(s => s !== null);
    var newScores = scores.slice();
    for (var i = 0; i < indexes.length; ++i)
    {
      newScores[indexes[i]] = {
        strategy: strategies[indexes[i]],
        id: null,
        loading: true,
        response: null,
        index: -1
      };
    }
    setScores(newScores);


    var request = api.buildScore(props.value.profiles, props.value.savings, props.value.allocations, props.value.income, props.value.expenses, strategies);
    api
      .getScore(request)
      .then(r => {
        var rScores = r.data[0].scores;
        for (var s = 0; s < rScores.length; ++s) {
          newScores[indexes[s]].id = r.data[0].id;
          newScores[indexes[s]].response = rScores[s];
          newScores[indexes[s]].index = s;
          newScores[indexes[s]].loading = false;
        }
        setScores(newScores);

        if (activeScore < 0) setActiveScore(0);
        setError(false);
        setIsLoaded(true);

        if (props.onChange) props.onChange(props.value.strategies, true);
      })
      .catch(e => {
        setError(true);
        setIsLoaded(true);
        setScores([null, null, null, null]);
        
        if (props.onChange) props.onChange(props.value.strategies, true);
      });
  }

  function selectScore(index) {
    setActiveScore(index);
  }

  function handleEditScore(index) {
    setEditStrategy(index);
    setOpenStrategyForm(true);
  }

  function handleDeleteScore(index) {
    var newScores = scores.slice();
    newScores.splice(index, 1);
    newScores.push(null);
    setScores(newScores);

    if (activeScore === index)
      setActiveScore(0);

    var newStrategies = props.value.strategies.slice();
    newStrategies.splice(index, 1);
    newStrategies.push(null);
    props.onChange(newStrategies, true);
  }

  function handleSaveStrategy(strategy) {
    var index = editStrategy ? editStrategy : props.value.strategies.indexOf(null);
    if (index >= 0) {
      var values = props.value.strategies.slice();
      values[index] = strategy;
      props.onChange(values, true);
    }
  }

  if (error) {
    return <Row>
      <Col xs={12}>
        <Box textAlign="center" m={6}>
          <Box m={2}>
            <Typography>Something went wrong. Please try again.</Typography>
          </Box>
          <Box m={2}>
            <Button onClick={() => calculateScore(props.value.strategies)} variant="contained" color="primary">Retry</Button>
          </Box>
        </Box>
      </Col>
    </Row>;
  }

  if (!isLoaded) {
    return <Row>
      <Col xs={12}>
        <LoadingProgressComponent height={400} label="Calculating Score..." />
      </Col>
    </Row>;
  }

  return (
    <Fade in={true}>
      <Row>
        <Col xs={12} style={{marginBottom: 20}}>
          <Typography>RISE&trade; Score Overview</Typography>
        </Col>

        {scores.filter(s => s !== null).map((value, index) =>
          <Col key={`score-${index}`} xs={12} md={3} style={{marginBottom: 20}}>
            <ScoreComponent
              key={index}
              label={`${value.strategy.allocation ? `${value.strategy.allocation}%` : ""} ${constants.strategies[value.strategy.type || ""]}`}
              score={(value.response || {}).score}
              loading={value.loading}
              isActive={activeScore === index}
              canEdit={value.strategy.type ? true : false}
              onClick={() => selectScore(index)}
              onEdit={() => handleEditScore(index)}
              onDelete={() => handleDeleteScore(index)}
              className={`score${index}`}
            />
          </Col>)}

        {props.value.strategies.filter(s => s === null).length > 0 ?
          <Col xs={12} md={3} style={{marginBottom: 20}}>
            <AddStrategyComponent
              onClick={() => setOpenStrategyForm(true)}
              className={`addStrategyButton`}
            />
          </Col> : null}

        <StrategyFormComponent
          open={openStrategyForm}
          value={editStrategy ? props.value.strategies[editStrategy] : null}
          retirementAge={props.value.profiles[0].retirementAge}
          onSave={handleSaveStrategy}
          onClose={() => { setOpenStrategyForm(false); setEditStrategy(null); }}
        />

        <Col xs={12} style={{marginBottom: 20}}>
          <Typography variant="h6" align="center">750-850: Excellent | 700-750: Very Good | 650-700: Good | 550-650: Fair | 350-550: Poor | 0-350: Very Poor</Typography>
          <Typography variant="subtitle1" align="center">Your client's RISE Score&trade; is based on the entries made in the previous screens, projected over market, inflation, and longevity scenarios, excluding the lifetime income solutions entered on the previous "Strategy" screen. A summary of your client's current portfolio allocation and other analytics are detailed below.</Typography>
        </Col>

        {scores[activeScore] && scores[activeScore].response ? 
          <>
            <Col xs={12} style={{marginBottom: 20}}>
              <ExpansionPanel className="overviewPanel">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="button">Overview</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <OverviewComponent score={scores[activeScore].response.score} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Col>

            <Col xs={12} style={{marginBottom: 20}}>
              <ExpansionPanel className="allocationPanel">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="button">Allocation Summary</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <AssetAllocationComponent allocations={scores[activeScore].response.portfolio} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Col>

            <Col xs={12} style={{marginBottom: 20}}>
              <ExpansionPanel className="statisticsPanel">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="button">Summary Statistics</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <StatisticsComponent statistics={{...scores[activeScore].response.risk, ...scores[activeScore].response.stats, ...scores[activeScore].response.ages}} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Col>

            <Col xs={12}>
              <ExpansionPanel className="analyticsPanel">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="button">Detailed Analytics</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <AnalyticsComponent scoreId={scores[activeScore].id} activeScore={scores[activeScore].index} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Col>
          </> : null}
      </Row>
    </Fade>
  );
}
