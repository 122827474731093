import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from '../../Shared/Grid';
import { Section, Group, Heading, Paragraph, HorizontalRule, Label } from '../../Shared/Typography';
import { Button, Tooltip } from '../../Shared/Controls';
import { Info } from '../../Shared/Icons';
import { NumberField } from '../../Shared/CustomControls';
import { hasErrors, getValidState, isInRange } from '../../services/ValidationService';

export default function InputsView({ value, onChange }) {
    value = value || {};
    const [currentAge, setCurrentAge] = useState((value.currentAge || '') + '');
    const [retirementAge, setRetirementAge] = useState((value.retirementAge || '') + '');
    const [claimAge, setClaimAge] = useState((value.claimAge || '') + '');
    const [currentEarnings, setCurrentEarnings] = useState((value.currentEarnings || '') + '');
    const [liveValidation, setLiveValidation] = useState(false);
    const [errors, setErrors] = useState(validate());

    const memoizedValidate = useCallback(validate, [currentAge, retirementAge, claimAge, currentEarnings]);
    useEffect(() => {
      if (liveValidation === true) {
        setErrors(memoizedValidate());
      }
    }, [currentAge, retirementAge, claimAge, currentEarnings, liveValidation, memoizedValidate]);

    function validate() {
      var errors = {};
      if (!isInRange(currentAge, 14, 120))    errors.currentAge = true;
      if (!isInRange(retirementAge, 14, 120)) errors.retirementAge = true;
      if (!isInRange(claimAge, 14, 120))      errors.claimAge = true;
      if (!isInRange(currentEarnings, 1, 999999999, true)) errors.currentEarnings = true;
      return errors;
    }

    function handleNext() {
      var errors = validate();
      if (hasErrors(errors)) {
        setErrors(errors);
        if (liveValidation === false) setLiveValidation(true);
      }
      else
        onChange && onChange({ currentAge, retirementAge, claimAge, currentEarnings });
    }

    return <>
      <Row>
        <Col style={{paddingLeft: 20, paddingRight: 20}}>

          <Section>
            <Heading>Estimate your Social Security Benefits</Heading>
            <Paragraph>Enter your current age, the age you will stop working (retirement age) and the age that you expect to begin claiming benefits.</Paragraph>
          </Section>

          <HorizontalRule />

          <Section>
            <Heading level="2">About you</Heading>
            <Row>
              <Col xs={4} md={3} lg={2}>
                <Group>
                  <Label>Current Age</Label>
                  <NumberField className="currentAgeField" value={currentAge} onChange={e => setCurrentAge(e.target.value)} valid={getValidState('currentAge', errors, liveValidation)} />
                </Group>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <Group>
                  <Label>Retirement Age</Label>
                  <NumberField className="retirementAgeField" value={retirementAge} onChange={e => setRetirementAge(e.target.value)} valid={getValidState('retirementAge', errors, liveValidation)} />
                </Group>
              </Col>
              <Col xs={4} md={3} lg={2}>
                <Group>
                  <Label>
                    Claiming Age
                    <Tooltip content={<>The age at which you plan to start claiming your social security benefit</>}>
                      <Info />
                    </Tooltip>
                  </Label>
                  <NumberField className="claimingAgeField" value={claimAge} onChange={e => setClaimAge(e.target.value)} valid={getValidState('claimAge', errors, liveValidation)} />
                </Group>
              </Col>
              <Col xs={12} md={3} lg={3} xl={2}>
                <Group>
                  <Label>Current Earnings</Label>
                  <NumberField className="currentEarningsField" value={currentEarnings} isCurrency placeholder="$0" onChange={e => setCurrentEarnings(e.target.value)} valid={getValidState('currentEarnings', errors, liveValidation)} />
                </Group>
              </Col>
            </Row>
          </Section>

          <HorizontalRule />

          <Section>
            <Row style={{justifyContent: 'flex-end'}}>
              <Col xs={5} sm={4} md={3} lg={2}>
                <Button className="nextButton" onClick={handleNext} style={{width: '100%'}}>Next</Button>
              </Col>
            </Row>
          </Section>

        </Col>
      </Row>
    </>;
}