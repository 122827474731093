import React from 'react';
import { Container, Row } from './Grid';
import { Hamburger } from './Icons';
import classes from './AppBar.module.scss';
import config from '../config';

export default function AppBar({ logo, subbrand, onExpand }) {
  return (
    <div className={classes.appBar}>
      <Container>
        <Row className={classes.innerAppBar}>

          <a href={config.WEB_URL} className={classes.brand}>
            <img src={logo} alt="" />
          </a>

          <div className={classes.barRight}>
            <h1>{subbrand}</h1>

            <button type="button" className={classes.menu} onClick={() => onExpand && onExpand()}>
              <Hamburger color="white" size="1.5rem" />
            </button>
          </div>

        </Row>
      </Container>
    </div>
  );
}