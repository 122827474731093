import React, { useState } from 'react';
import InputsView from './components/InputsView';
import EarningsInputView from './components/EarningsInputView';
import ResultsView from './components/ResultsView';

export default function App() {
  const [inputs, setInputs] = useState(null);
  const [screen, setScreen] = useState('inputs');

  const handleChange = (newValues, nextScreen) => {
    setInputs({...inputs, ...newValues});
    setScreen(nextScreen);
  }

  switch (screen) {
    case 'inputs':
      return <InputsView value={inputs} onChange={(values) => { handleChange(values, 'earnings'); }} />;
    case 'earnings':
      return <EarningsInputView value={inputs} onChange={(values) => { handleChange(values, 'results') }} onBack={() => { setScreen('inputs')}} />;
    case 'results':
      return <ResultsView value={inputs} onBack={() => { setScreen('earnings'); }} />;
    default:
      return null;
  }
}
