import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";

export default class LoadingProgressComponent extends React.Component {
  render() {
    return <Box height={this.props.height} display="flex" alignItems="center" justifyContent="center">
      <Box m={2}><CircularProgress /></Box>
      <Box m={2}><Typography variant="h5">{this.props.label}</Typography></Box>
    </Box>
  }
}
