import React from 'react';
import classes from './Typography.module.scss';

export function Heading({ level = 1, children, ...props }) {
  level = parseInt(level) || 1;

  switch (level) {
    case 2:
      return <h2 {...props} className={classes.h2}>{children}</h2>;
    case 3:
      return <h3 {...props} className={classes.h3}>{children}</h3>;
    case 4:
      return <h4 {...props} className={classes.h4}>{children}</h4>;
    case 5:
      return <h5 {...props} className={classes.h5}>{children}</h5>;
    case 6:
      return <h6 {...props} className={classes.h6}>{children}</h6>;
    default:
      return <h1 {...props} className={classes.h1}>{children}</h1>;
  }
}

export function Display({ variant = 'base', level = 1, weight = "normal", className, children, ...props }) {
  const classNames = [
    classes['display-' + variant],
    classes['display-' + level],
    classes['display-' + weight]
  ];
  
  if (className) classNames.push(className);
  
  return <span {...props} className={classNames.join(' ')}>
    {children}
  </span>
}

export function Paragraph({ children, ...props }) {
  return <p {...props} className={classes.p}>
    {children}
  </p>;
}

export function HorizontalRule(props) {
  return <hr {...props} className={classes.hr} />;
}

export function Label({ children, ...props }) {
  return <label {...props} className={classes.label}>
    {children}
  </label>;
}

export function Section({ children, ...props }) {
  return <div {...props} className={classes.section}>
    {children}
  </div>;
}

export function Group({ children, ...props }) {
  return <div {...props} className={classes.group}>
    {children}
  </div>;
}