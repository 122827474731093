export default class RequestBuilder
{
  constructor() {
    this.primary = null;
    this.secondary = null;
    this.strategies = [];
    this.accounts = [];
    this.cashflows = [];
  }

  setPrimary(currentAge, retirementAge, gender, health) {
    this.primary = {
      currentAge: parseInt(currentAge),
      retirementAge: parseInt(retirementAge),
      gender,
      health
    };
  }

  setSecondary(currentAge, retirementAge, gender, health) {
    this.secondary = {
      currentAge: parseInt(currentAge),
      retirementAge: parseInt(retirementAge),
      gender,
      health
    };
  }

  addAccount(amount, allocations, contributions, inflationType, inflationRate) {
    var mappedAllocations;
    if (Array.isArray(allocations)) {
      var currentYear = 1;
      mappedAllocations = [];
      for (var i = 0; i < allocations.length; ++i) {
        mappedAllocations.push(this.buildAllocation(allocations[i], currentYear, allocations[i].timeSteps));
        currentYear += allocations[i].timeSteps || 0;
      }
    }
    else
    {
      mappedAllocations = [this.buildAllocation(allocations)];
    }

    var account = {
      amount: parseFloat(amount),
      ownership: "Joint",
      taxType: "PreTaxAdvantaged",
      allocations: mappedAllocations
    };

    contributions = parseFloat(contributions);
    if (contributions > 0) {
      var contribution = { amount: contributions };

      if (inflationType === "scenario")
        contribution.inflation = { scenario: true };
      else if (inflationType === "rate")
        contribution.inflation = { rate: parseFloat(inflationRate) / 100 };

      account.contributions = [contribution];
    }

    this.accounts.push(account);
  }

  buildAllocation(allocations, startTime = null, timeSteps = null) {
    return {
      funds: [
        { fund: "LargeCap", allocation: parseFloat(allocations.largeCap || 0) / 100 },
        { fund: "MidCap", allocation: parseFloat(allocations.smallMidCap || 0) / 100 },
        { fund: "International", allocation: parseFloat(allocations.developedIntEquities || 0) / 100 },
        { fund: "Emerging", allocation: parseFloat(allocations.emergingMarketEquities || 0) / 100 },
        { fund: "Bonds", allocation: parseFloat(allocations.bonds || 0) / 100 },
      ],
      startTime,
      timeSteps
    };
  }

  addCashFlow(amount, type, inflationType, inflationRate, futureDollars, retirementStart) {
    var cashflow = {
      amount: parseFloat(amount),
      type,
      ownership: "Joint",
    };

    var isIncome = amount > 0;

    if (inflationType === "scenario")
      cashflow.inflation = { scenario: true, allowDeflation: !isIncome };
    else if (inflationType === "rate")
      cashflow.inflation = { rate: parseFloat(inflationRate) / 100 };

    if (futureDollars === true) {
      cashflow.startTime = retirementStart;
      cashflow.futureDollars = true;
    }
    
    this.cashflows.push(cashflow);
  }

  addStrategy(productType, allocation, issueAge, withdrawalAge, fundingStrategy, vaEquitiesAllocation, vaBondsAllocation, effectiveTaxRate) {
    var strategy = {};

    if (effectiveTaxRate) {
      effectiveTaxRate = parseFloat(effectiveTaxRate);
      strategy.taxes = { effectiveTaxRate: effectiveTaxRate / 100 };
    }

    if (productType) {
      var product = {
        product: productType,
        allocation: parseFloat(allocation) / 100,
        issueAge: parseInt(issueAge),
        withdrawalAge: parseInt(withdrawalAge)
      };

      if (productType === "VA") {
        product.funds = [
          { fund: "LargeCap", allocation: parseFloat(vaEquitiesAllocation) / 100 },
          { fund: "Bonds", allocation: parseFloat(vaBondsAllocation) / 100 }
        ];
      }

      strategy.products = [product];
      strategy.fundingStrategy = fundingStrategy;
    }

    this.strategies.push(strategy);
  }

  buildRequest() {
    return [
      {
        primary: this.primary,
        secondary: this.secondary,
        strategies: this.strategies,
        accounts: this.accounts,
        cashflows: this.cashflows
      }
    ];
  }
}