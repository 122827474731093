import axios from 'axios';
import RequestBuilder from "./RequestBuilder";
import config from "../config";

export default {
  buildScore(profiles, savings, allocations, income, expenses, strategies) {
    var builder = new RequestBuilder();

    var primary = profiles[0];
    builder.setPrimary(
      primary.currentAge,
      primary.retirementAge,
      primary.gender || null,
      primary.health || null
    );

    var secondary = profiles[1];
    if (secondary && secondary.currentAge > 0)
      builder.setSecondary(
        secondary.currentAge,
        secondary.retirementAge,
        secondary.gender || null,
        secondary.health || null
      );

    builder.addAccount(
      (savings || {}).amount || 0,
      allocations || {},
      (savings || {}).contributions || 0,
      (savings || {}).inflationType || "none",
      (savings || {}).inflationRate || 0
    );

    Object.keys((income || {}).cashflows || {}).forEach(k => {
      var cashflow = (income.cashflows[k] || {});
      var amount = parseFloat(cashflow.amount || 0);
      if (amount > 0)
        builder.addCashFlow(amount, "Standard", cashflow.inflationType, cashflow.inflationRate, cashflow.dollarType === "future", primary.retirementAge - primary.currentAge);
    })
    
    Object.keys(expenses || {}).forEach(k => {
      var cashflow = (expenses[k] || {});
      var amount = parseFloat(cashflow.amount || 0);
      if (amount > 0)
        builder.addCashFlow(-amount, k === "medical" ? "Medical" : "Standard", cashflow.inflationType, cashflow.inflationRate, cashflow.dollarType === "future", primary.retirementAge - primary.currentAge);
    })
    
    for (var a = 0; a < strategies.length; ++a) {
      var annuity = strategies[a];
      if (annuity) {
        if (!annuity.type)
          builder.addStrategy(undefined, undefined, undefined, undefined, undefined, undefined, undefined, income.effectiveTaxRate);
        else
          builder.addStrategy(
            annuity.type,
            annuity.allocation || 0,
            annuity.issueAge || null,
            annuity.withdrawalAge || null,
            annuity.fundingStrategy || null,
            annuity.type === "VA" ? 70 : undefined,
            annuity.type === "VA" ? 30 : undefined,
            income.effectiveTaxRate
          );
      }
    }

    return builder.buildRequest();
  },

  getScore(data) {
    return axios.post(`${config.API_URL}/rise/calculate`, data);
  },
    
  getAverageChart(id, strategy) {
    return axios.get(`${config.API_URL}/rise/analytics/average?strategy=${strategy + 1}&profileId=${id}`);
  },
    
  /// chartType: income, expenses, returns, supportability
  getSpecificChart(id, strategy, scenario, chartType) {
    return axios.get(`${config.API_URL}/rise/analytics/${chartType}?profileId=${id}&strategy=${strategy + 1}&scenario=${scenario}`);
  }
}