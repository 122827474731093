import React from 'react';
import { Fade, Typography, TextField, InputAdornment } from '@material-ui/core';
import { Row, Col } from '../../Shared/Grid';
import LineItemComponent from '../Shared/LineItemComponent';
import validators from '../../validators';
import constants from '../../constants';

export default class IncomeComponent extends React.Component {
  constructor(props) {
    super(props);

    this.firstFieldRef = React.createRef();

    this.state = {
      errors: validators.validateIncome(props.value || {})
    };
    
    // validate on initialization of the component
    if (this.props.onChange) {
      var isAllValid = validators.isValid(this.state.errors);
      this.props.onChange(props.value, isAllValid);
    }
  }

  componentDidMount = () => {
    if (this.props.onViewInit)
      this.props.onViewInit();
  }

  handleSlideEntered = () => {
    if (this.firstFieldRef.current)
      this.firstFieldRef.current.focus();
  }

  handleLineItemChange = (id, value) => {
    var newValue = (this.props.value || {});
    if (!newValue.cashflows) newValue.cashflows = {};
    newValue.cashflows[id] = value;

    var errors = validators.validateIncome(newValue);
    this.setState({ errors });

    if (this.props.onChange) {
      var isAllValid = validators.isValid(errors);
      this.props.onChange(newValue, isAllValid);
    }
  }

  handleEffectiveTaxRateChange = (value) => {
    var newValue = (this.props.value || {});
    newValue.effectiveTaxRate = value;

    var errors = validators.validateIncome(newValue);
    this.setState({ errors });

    if (this.props.onChange) {
      var isAllValid = validators.isValid(errors);
      this.props.onChange(newValue, isAllValid);
    }
  }

  render() {
    return (
      <Fade in={true} onEntered={this.handleSlideEntered}>
        <div>
          <Typography variant="h5" gutterBottom={true}>Income During Retirement</Typography>
          <Typography gutterBottom={true}>
            Please enter your expected income during retirement in this section. Income items should be entered as annual pre-tax amounts.
          </Typography>
          <Typography gutterBottom={true}>
            Please also indicate whether or not these amounts should be adjusted for inflation and if they are in today's (nominal) dollars or (real) dollars in retirement. If the amounts entered are specified in today's (nominal) dollars then we will apply inflation to this cash flow from today. If the amounts entered are specified as (real) dollars in retirement then we will apply inflation to this cash flow from your retirement age.
            </Typography>
          <Typography gutterBottom={true}>
            Please refer to our assumption documentation for more information about capital market assumptions.
          </Typography>

          <Row>
            <Col xs={12}>
              {constants.income.map((li, index) => 
                <LineItemComponent key={li.id}
                  label={li.label}
                  helperText={li.helperText}
                  value={(this.props.value.cashflows || {})[li.id]}
                  showErrors={this.props.showErrors}
                  errors={(this.state.errors.cashflows || {})[li.id]}
                  onChange={(value, isValid) => this.handleLineItemChange(li.id, value, isValid)}
                  amountRef={index === 0 ? this.firstFieldRef : null}
                  className={`${li.id}LineItem`}
                />
              )}
            </Col>
            <Col xs={12}>
              <TextField
                InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                label="Estimated Effective Tax Rate During Retirement"
                helperText=""
                value={this.props.value.effectiveTaxRate || ""}
                error={this.props.showErrors ? this.state.errors.effectiveTaxRate : null}
                onChange={(e) => this.handleEffectiveTaxRateChange(e.target.value)}
                fullWidth
                margin="normal"
                className="effectiveTaxRateField"
              />
            </Col>
          </Row>
        </div>
      </Fade>
    );
  }
}
