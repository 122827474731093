import FormatService from './FormatService';

function hasErrors(errors) {
  return Object.keys(errors).filter(k => errors[k] === true).length > 0
}

function isInRange(value, min, max, isCurrency) {
  if ((isCurrency ? FormatService.isCurrency : FormatService.isNumber)(value || '')) {
    var parsed = FormatService.unformat(value || '');
    return parsed >= min && parsed <= max;
  }
  return false;
}

function getValidState(key, errors, isShowingErrors) {
  if (isShowingErrors === true)
    return !(errors[key] === true);
  return null;
}

export {
  hasErrors,
  isInRange,
  getValidState
}