import React from 'react';
import { Card, CardContent, Typography, Box, CircularProgress, IconButton, withStyles } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const styles = {
  score: {
    cursor: "pointer"
  },
  active: {
    borderBottom: "6px solid #00A562"
  }
};

class ScoreComponent extends React.Component {
  handleEdit = (e) => {
    e.stopPropagation();
    if (this.props.onEdit) this.props.onEdit();
  }

  handleDelete = (e) => {
    e.stopPropagation();
    if (this.props.onDelete) this.props.onDelete();
  }

  render() {
    const { classes, onClick, isActive, loading, canEdit } = this.props;
    var cardClasses = [ this.props.className ];
    if (!loading) cardClasses.push(classes.score);
    if (isActive) cardClasses.push(classes.active);

    return (
      <Card className={cardClasses.join(' ')} onClick={onClick}>
        <CardContent style={{position: 'relative'}}>
      
          {!loading && canEdit ?
            <div style={{position: 'absolute', top: 0, right: 0, padding: 10}}>
              <IconButton size="small" onClick={this.handleEdit} className="editButton">
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton size="small" onClick={this.handleDelete} className="deleteButton">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          : null}

          <Box paddingY={6} align="center">
            {!loading
              ? <Typography variant="h3">
                  {this.props.score}
                </Typography>
              : <CircularProgress size={44} />
            }
            
            <Typography variant="subtitle2" align="center">
              {this.props.label}
            </Typography>
          </Box>

        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(ScoreComponent);
