import React from 'react';
import { Link } from '@reach/router';
import Overlay from './Overlay';
import classes from './AppMenu.module.scss';

export default function AppMenu({ opened, links, onClose }) {
  function close() {
    onClose && onClose();
  }

  return (
    <div className={classes.appMenu}>
      {opened === true &&
        <Overlay onClose={close}>
          <div style={{backgroundColor: 'white', height: '100%', width: 300, padding: '0.5rem 0'}}>
            {links && links.map((l, index) =>
              <Link to={l.href} key={index} onClick={close} style={{display: 'block', padding: '0.5rem 1rem'}}>{l.label}</Link>
            )}
          </div>
        </Overlay>
      }
    </div>
  );
}