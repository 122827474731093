import React from "react";
import { Fade, TextField, Typography, FormControl, FormControlLabel, InputLabel, InputAdornment, Select, MenuItem, Switch, Slider } from '@material-ui/core';
import { Col, Row } from '../../Shared/Grid';
import { ResponsiveContainer, AreaChart, Area, Tooltip } from 'recharts';
import NumberFormatComponent from '../Shared/NumberFormatComponent';
import validators from '../../validators';

export default class SavingsViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.firstFieldRef = React.createRef();

    var errors = validators.validateSavings(props.value || {});
    var inflation = (props.value || {}).inflationType || "none";
    this.state = {
      errors,
      includeInflation: inflation !== "none"
    };

    // validate on initialization of the component
    var isValid = Object.keys(errors).length === 0;
    if (this.props.onChange) this.props.onChange(props.value, isValid);
  }

  componentDidMount = () => {
    if (this.props.onViewInit)
      this.props.onViewInit();
  }

  handleSlideEntered = () => {
    if (this.firstFieldRef.current)
      this.firstFieldRef.current.focus();
  }

  handleIncludeInflation = (v) => {
    this.setState({
      includeInflation: v,
    });
    this.setValue({ inflationType: v ? "scenario" : "none" });
  }

  setValue(newValue) {
    var savings = { ...this.props.value, ...newValue };
    var errors = validators.validateSavings(savings);
    this.setState({ errors });
    var isValid = Object.keys(errors).length === 0;
    if (this.props.onChange) this.props.onChange(savings, isValid);
  }

  getChartData() {
    var yearsUntilRetirement = 30;
    var startingAmount = parseFloat(this.props.value.amount || 0);
    var contributionAmount = parseFloat(this.props.value.contributions || 0);
    var inflationRate = this.props.value.inflationType === "rate"
      ? (this.props.value.inflationRate / 100 || 0.0)
      : (this.props.value.inflationType === "scenario" ? 0.0248 : 0.0);
    var values = [];
    var lastAccountValue = startingAmount;

    for (var i = 0; i <= yearsUntilRetirement; ++i) {
      values.push({ value: lastAccountValue });
      lastAccountValue += contributionAmount * Math.pow(1 + inflationRate, i);
    }

    return values;
  };

  render() {
    var value = this.props.value || {};
    var errors = this.state.errors || {};
    var chartData = this.getChartData();

    return (
      <Fade in={true} onEntered={this.handleSlideEntered}>
        <div>
          <Row>
            <Col xs={12}>
              <Typography variant="h5" gutterBottom={true}>Estimate your Savings</Typography>
              <Typography gutterBottom={true}>
                Enter your current portfolio amount, expected annual pre-tax savings contributions, and asset allocation.
                Please also indicate wheter or not these expected savings contributions should be adjusted for inflation and enter
                them in current (nominal) dollars.
              </Typography>
            </Col>
          </Row>

          <Row>
            <Col>
            </Col>
          </Row>
         

          <Row>
            <Col xs={12} sm={6} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <div style={{marginBottom: 24}}>
                <Typography gutterBottom={true}>Your Savings Amount</Typography>

                <div style={{marginLeft: 6, marginRight: 6}}>
                  <Slider
                    value={parseInt(value.amount) || 0}
                    onChange={(e, v) => this.setValue({ amount: v })}
                    step={5000}
                    max={5000000}
                    className="amountSlider"
                  />
                </div>

                <TextField
                  InputProps={{
                    inputComponent: NumberFormatComponent,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { style: { textAlign: "right" } }
                  }}
                  value={value.amount || "0"}
                  error={this.props.showErrors ? errors.amount : null}
                  onChange={e => this.setValue({ amount: e.target.value })}
                  margin="none"
                  inputRef={this.firstFieldRef}
                  className="amountField"
                  style={{"float": "right"}}
                />
              </div>

              <div>
                <Typography gutterBottom={true}>Additional Contributions</Typography>

                <div style={{marginLeft: 6, marginRight: 6}}>
                  <Slider
                    value={parseInt(value.contributions) || 0}
                    onChange={(e, v) => this.setValue({ contributions: v })}
                    step={500}
                    max={50000}
                    className="contributionsSlider"
                  />
                </div>

                <div style={{"display": "flex", "justifyContent": "space-between"}}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={this.state.includeInflation}
                        onChange={(e) => this.handleIncludeInflation(e.target.checked)}
                        className="includeInflationSwitch"
                      />
                    }
                    label="Apply Inflation"
                  />

                  <TextField
                    InputProps={{
                      inputComponent: NumberFormatComponent,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      inputProps: { style: { textAlign: "right" } }
                    }}
                    value={value.contributions || "0"}
                    error={this.props.showErrors ? errors.contributions : null}
                    onChange={e => this.setValue({ contributions: e.target.value })}
                    margin="none"
                    className="contributionsField"
                    style={{"float": "right"}}
                  />
                </div>

                {!!this.state.includeInflation ?
                  <Row>
                    <Col xs={value.inflationType === "rate" ? 9: 12}>
                      <FormControl
                        fullWidth
                        margin="none"
                      >
                        <InputLabel>Type of Inflation to Apply</InputLabel>
                        <Select
                          value={value.inflationType || "none"}
                          onChange={(e) => this.setValue({ inflationType: e.target.value })}
                          className="inflationSelect"
                        >
                          <MenuItem value="scenario">Simulated Scenarios</MenuItem>
                          <MenuItem value="rate">Fixed Rate</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>

                    {value.inflationType === "rate" ? 
                      <Col xs={3}>
                        <TextField
                          InputProps={{
                            inputComponent: NumberFormatComponent,
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                          }}
                          label="Rate"
                          value={value.inflationRate || ""}
                          error={this.props.showErrors ? errors.inflationRate : null}
                          onChange={(e) => this.setValue({ inflationRate: e.target.value })}
                          fullWidth
                          required
                          className="inflationRateField"
                        />
                      </Col>
                    : null}
                  </Row>
                : null}
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <ResponsiveContainer height={300}>
                <AreaChart data={chartData}>
                  <Area dataKey="value" stroke="#397532" fill="#5f8d57" />
                  <Tooltip
                    labelFormatter={(name) => `Year ${name}`}
                    formatter={(value) => {
                      return [
                        !isNaN(value) ? value.toLocaleString(undefined, { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 }) : "",
                        undefined
                      ];
                    }}
                  />
                </AreaChart>
              </ResponsiveContainer>
              
              <Typography variant="caption" display="block" align="center">
                This chart only illustrates the effect of contributions on your savings amount, and does not take into account market returns.
              </Typography>
            </Col>
          </Row>
        </div>
      </Fade>
    );
  }
}