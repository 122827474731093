export default {

  colors: ['#00425e', '#30607c', '#54809b', '#77a1bb', '#9bc4dd', '#c0e8ff'],

  savings: [
    { id: "largeCap", label: 'U.S Large Cap' },
    { id: 'smallMidCap', label: 'U.S. Small/Mid Cap' },
    { id: 'developedIntEquities', label: 'Developed International Equities' },
    { id: 'emergingMarketEquities', label: 'Emerging Market Equities' },
    { id: 'bonds', label: 'U.S Bonds' }
  ],

  income: [
    { id: "socialSecurity", label: "Social Security Benefit", helperText: "The average benefit is $17,520 a year" },
    { id: "pension", label: "Pension Income", helperText: "The average American pension is $36,000 a year" },
    { id: "annuity", label: "Annuity Income" },
    { id: "rentalProperty", label: "Rental Property Income" },
    { id: "other", label: "Other Income" }
  ],

  expenses: [
    { id: "basicLiving", label: "Basic Living Expenses", helperText: "The average American living expenses are $45,756 a year" },
    { id: "medical", label: "Medical Expenses", helperText: "The average American medical expenses are $6,000 a year" },
    { id: "other", label: "Other Expenses" }
  ],

  strategies: {
    "": "Baseline",
    "DIA": "Income Annuity",
    "VA": "Variable Annuity",
    "FIA": "Fixed Income Annuity"
  }
}