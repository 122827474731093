import React from 'react';
import { TextField, InputAdornment, Select, FormControl, InputLabel, FormHelperText, MenuItem } from '@material-ui/core';
import { Row, Col } from '../../Shared/Grid';
import NumberFormatComponent from '../Shared/NumberFormatComponent';

export default class LineItemComponent extends React.Component {
  setValue = (amount, inflationType, inflationRate, dollarType) => {
    if (this.props.onChange) {
      var value = { amount, inflationType, inflationRate, dollarType };
      this.props.onChange(value);
    }
  }

  render() {
    const value = this.props.value || {};
    const errors = this.props.errors || {};

    return (
      <Row className={this.props.className} style={{alignItems: "center"}}>
        <Col xs={12} sm={12} md={4}>
          <TextField
            inputRef={this.props.amountRef}
            InputProps={{
              inputComponent: NumberFormatComponent,
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            label={this.props.label}
            helperText={this.props.helperText}
            value={value.amount || ""}
            error={this.props.showErrors ? errors.amount : null}
            onChange={(e) => this.setValue(e.target.value, value.inflationType, value.inflationRate, value.dollarType)}
            fullWidth
            margin="normal"
            className="amountField"
          />
        </Col>

        <Col xs={12} sm={6} md={4}>
          <Row>
            <Col xs={value.inflationType === "rate" ? 9: 12}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Type</InputLabel>
                <Select value={value.inflationType || "none"} onChange={(e) => this.setValue(value.amount, e.target.value, value.inflationRate, value.dollarType)} className="inflationTypeField">
                  <MenuItem value="none">No Inflation</MenuItem>
                  <MenuItem value="scenario">Simulated Scenarios</MenuItem>
                  <MenuItem value="rate">Fixed Rate</MenuItem>
                </Select>
                {this.props.helperText && this.props.helperText.length > 0 ? <FormHelperText></FormHelperText> : null}
              </FormControl>
            </Col>

            {value.inflationType === "rate" ? 
              <Col xs={3}>
                <TextField
                  InputProps={{
                    inputComponent: NumberFormatComponent,
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  label="Rate"
                  value={value.inflationRate || ""}
                  error={this.props.showErrors ? errors.inflationRate : null}
                  onChange={(e) => this.setValue(value.amount, value.inflationType, e.target.value, value.dollarType)}
                  fullWidth
                  margin="normal"
                  required
                  className="inflationRateField"
                />
              </Col>
            : null}
          </Row>
        </Col>

        <Col xs={12} sm={6} md={4}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Amount in</InputLabel>
            <Select value={value.dollarType || "current"} onChange={(e) => this.setValue(value.amount, value.inflationType, value.inflationRate, e.target.value)} disabled={(value.inflationType || "none") === "none"} className="dollarTypeField">
              <MenuItem value="current">Today's Dollars</MenuItem>
              <MenuItem value="future">Dollars at Retirement</MenuItem>
            </Select>
            {this.props.helperText && this.props.helperText.length > 0 ? <FormHelperText></FormHelperText> : null}
          </FormControl>
        </Col>
      </Row>
    );
  }
}
