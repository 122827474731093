import React, { useEffect, useState } from 'react';
import { Row, Col } from '../../Shared/Grid';
import { Section, Group, Heading, Paragraph, HorizontalRule } from '../../Shared/Typography';
import { Button } from '../../Shared/Controls';
import IncomeChart from './IncomeChart';
import IncomeModal from './IncomeModal';
import EarningsService from '../services/EarningsService';
import FormatService from '../../services/FormatService';

export default function EarningsInputView({ value, onBack, onChange }) {
  const currentAge = FormatService.unformat(value.currentAge);
  const retirementAge = FormatService.unformat(value.retirementAge);
  const claimAge = FormatService.unformat(value.claimAge);
  const currentEarnings = FormatService.unformat(value.currentEarnings);

  const [values, setValues] = useState(() => {
    if (value.earnings)
      return value.earnings;
    
    const initial = {};
    initial[currentAge] = currentEarnings;
    return initial;
  });
  const [earnings, setEarnings] = useState(null);
  const [editEarnings, setEditEarnings] = useState({ open: false });

  useEffect(() => {
    if (values && Object.keys(values).length > 0)
      setEarnings(
        EarningsService.project(currentAge, retirementAge, claimAge, values)
      );
  }, [currentAge, retirementAge, claimAge, values]);

  function handleChange() {
    onChange && onChange({ earnings: values });
  }

  return <>
    <Row>
      <Col style={{paddingLeft: 20, paddingRight: 20}}>

        <Section>
          <Heading>Your Earnings History</Heading>
          <Paragraph>All past and future earnings are used to estimate your social security benefit. We've tried to estimate these for you using your current earnings and national wage inflation averages. If these don't look correct, you may change these by selecting individual bars. You don't need to provide every year as we'll do our best to fill in the gaps.</Paragraph>
        </Section>

        <HorizontalRule />

        <Section>
          <Heading level="2">Your earnings history</Heading>

          <Row>
            <Col xs={12}>
              <Group>
                {earnings && earnings.length > 0 &&
                  <IncomeChart currentAge={currentAge} data={earnings}
                    onClick={(datum, index) => {
                      const age = earnings[index].age;
                      setEditEarnings({
                        open: true,
                        age,
                        isSet: age !== currentAge && values && !isNaN(parseInt(values[age])),
                        amount: (values && !isNaN(parseInt(values[age])) ? values[age] : null) || earnings[index].amount
                      }); 
                    }}
                  />
                }
              </Group>
            </Col>
          </Row>
        </Section>

        <HorizontalRule />

        <Section>
          <Row style={{justifyContent: 'space-between'}}>
            <Col xs={5} sm={4} md={3} lg={2}>
              <Button className="backButton" variant="outline" onClick={onBack} style={{width: '100%'}}>Go Back</Button>
            </Col>
            <Col xs={5} sm={4} md={3} lg={2}>
              <Button className="nextButton" onClick={handleChange} style={{width: '100%'}}>Calculate</Button>
            </Col>
          </Row>
        </Section>

      </Col>
    </Row>

    <IncomeModal value={editEarnings}
      onAccept={(age, amount) => {
        const newValues = { ...values };
        newValues[age] = amount;
        setValues(newValues);
        setEditEarnings(e => { return { ...e, open: false, age, amount }; });
      }}
      onUnset={(age) => {
        const newValues = { ...values };
        delete newValues[age];
        setValues(newValues);
        setEditEarnings(e => { return { ...e, open: false, age }; });
      }}
      onClose={() => setEditEarnings(e => { return { ...e, open: false }; })}
    />
  </>;
}