import React from 'react';
import { Typography, Divider } from "@material-ui/core";
import { Row, Col } from '../../Shared/Grid';

export default class StatisticsComponent extends React.Component {
  render() {
    const stats = this.props.statistics;
    return <Row style={{alignItems: 'center'}}>
      <Col xs={12} md={3} style={{paddingTop: 40, paddingBottom: 40}}>
        <Typography variant="h3" align="center" className="cteLabel">{stats.CTE90}-{stats.CTE0}%</Typography>
        <Typography variant="subtitle2" align="center">Estimated Income Expense Coverage</Typography>
      </Col>

      <Col xs={12} md={9}>
        <Typography variant="h5" gutterBottom={true}>Income-to-Expense Ratio</Typography>
        <Typography>The estimated RISE Score&trade; includes an estimate for how much expected income from all sources entered may cover expenses across a range of scenarios. These scenarios include market returns based on the asset allocation entered, inflation scenarios applied to income and expenses based on the entries provided, and longevity scenarios assuming an industry-accepted annuitant mortality table. Based on this information the expected income in retirement, including portfolio withdrawals, may cover {stats.CTE0}% of expenses in average scenarios and {stats.CTE90}% of expenses in the worst 10% of scenarios. The expected income in retirement, excluding portfolio withdrawals may cover {stats.CTE0ExclWithdrawals}% of expenses in average scenarios and {stats.CTE90ExclWithdrawals}% of expenses in the worst 10% of scenarios.</Typography>
      </Col>


      <Col xs={12}>
        <Divider light={true} style={{margin: '20px 0'}}/>
      </Col>


      <Col xs={12} md={3} style={{paddingTop: 40, paddingBottom: 40}}>
        <Typography variant="h3" align="center" className="averageReturnLabel">{stats.averageReturn}%</Typography>
        <Typography variant="subtitle2" align="center">Estimated Average Return</Typography>
      </Col>

      <Col xs={12} md={9}>
        <Typography variant="h5" gutterBottom={true}>Average Return</Typography>
        <Typography>The estimated RISE Score&trade; was developed over a range of market scenarios. Based on the asset allocation entered, the average return assumed across all scenarios is {stats.averageReturn}% . Please refer to our assumption documentation for more information about capital market assumptions.</Typography>
      </Col>


      <Col xs={12}>
        <Divider light={true} style={{margin: '20px 0'}} />
      </Col>


      <Col xs={12} md={3} style={{paddingTop: 40, paddingBottom: 40}}>
        <Typography variant="h3" align="center" className="averageInflationLabel">{stats.averageInflation}%</Typography>
        <Typography variant="subtitle2" align="center">Estimated Average Inflation</Typography>
      </Col>

      <Col xs={12} md={9}>
        <Typography variant="h5" gutterBottom={true}>Average Inflation</Typography>
        <Typography>The estimated RISE Score&trade; was developed over a range of inflation scenarios. The average inflation rate assumed across all scenarios for income items is {stats.averageInflation}%, {stats.averageInflationNonMedical}% for non-medical expenses and {stats.averageInflationMedical}% for medical expenses. Please refer to our assumption documentation for more information about capital market assumptions.</Typography>
      </Col>


      <Col xs={12}>
        <Divider light={true} style={{margin: '20px 0'}} />
      </Col>


      <Col xs={12} md={3} style={{paddingTop: 40, paddingBottom: 40}}>
        <Typography variant="h3" align="center" className="lifeExpectancyLabel">{stats.primaryLifeExpectancy} yrs</Typography>
        <Typography variant="subtitle2" align="center">Estimated Life Expectancy</Typography>
      </Col>

      <Col xs={12} md={9}>
        <Typography variant="h5" gutterBottom={true}>Average Life Expectancy</Typography>
        <Typography>The estimated RISE Score&trade; was developed over a range of longevity scenarios based on an industry-accepted annuitant mortality table. Based on this table, the 25th - 50th percentile of longevity scenarios used to estimate the RISE Score&trade; are between ages {stats.primaryLifeExpectancyLower}-{stats.primaryLifeExpectancyUpper}. Life expectancy from this mortality table from retirement age is {stats.primaryLifeExpectancy}. Please refer to our assumption documentation for more information about our longevity scenarios.</Typography>
      </Col>
    </Row>;
  }
}
