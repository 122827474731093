import React from 'react';
import { Fade, Typography } from '@material-ui/core';
import LineItemComponent from '../Shared/LineItemComponent';
import validators from '../../validators';
import constants from '../../constants';

export default class ExpensesComponent extends React.Component {
    constructor(props) {
        super(props);

        this.firstFieldRef = React.createRef();

        this.state = {
          errors: validators.validateExpenses(props.value || {})
        };
        
        // validate on initialization of the component
        if (this.props.onChange) {
          var isAllValid = validators.isValid(this.state.errors);
          this.props.onChange(props.value, isAllValid);
        }
    }
    
    componentDidMount = () => {
      if (this.props.onViewInit)
        this.props.onViewInit();
    }

    handleSlideEntered = () => {
      if (this.firstFieldRef.current)
        this.firstFieldRef.current.focus();
    }

    handleChange = (id, value) => {
      var values = (this.props.value || {});
      values[id] = value;

      var errors = validators.validateExpenses(values);
      this.setState({ errors });

      if (this.props.onChange) {
        var isAllValid = validators.isValid(errors);
        this.props.onChange(values, isAllValid);
      }
    }

    render() {
      return (
        <Fade in={true} onEntered={this.handleSlideEntered}>
          <div>
            <Typography variant="h5" gutterBottom={true}>Expenses During Retirement</Typography>
            <Typography gutterBottom={true}>
              Please enter your expected expenses during retirement in this section. Expense items should be entered as annual post-tax amounts.
            </Typography>
            <Typography gutterBottom={true}>
              Expense amounts should also be entered in today's (nominal) dollars. Don't worry, we will adjust these amounts for inflation.
            </Typography>
            <Typography gutterBottom={true}>
              Please refer to our assumption documentation for more information about capital market assumptions.
            </Typography>

            {((this.state.errors.general || {}).required || false) && this.props.showErrors ?
              <Typography color="error">
                At least one expense is required.
              </Typography>
            : null}

            {(constants.expenses || []).map((li, index) => 
              <LineItemComponent key={li.id}
                label={li.label}
                helperText={li.helperText}
                value={this.props.value[li.id]}
                showErrors={this.props.showErrors}
                errors={this.state.errors[li.id]}
                onChange={(value, isValid) => this.handleChange(li.id, value, isValid)}
                amountRef={index === 0 ? this.firstFieldRef : null}
                className={`${li.id}LineItem`}
              />
            )}  
          </div>
        </Fade>
      );
    }
}
