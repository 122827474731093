import React from 'react';
import FormatService from '../services/FormatService';
import { TextField } from './Controls';

export function NumberField({ onChange, isCurrency = false, ...props }) {
  function handleBlur(e) {
    var valid = isCurrency ? FormatService.isCurrency(e.target.value) : FormatService.isNumber(e.target.value);
    if (valid) {
      var oldValue = e.target.value;
      var parsed = FormatService.unformat(e.target.value);
      var newValue = isCurrency ? FormatService.toCurrency(parsed) : FormatService.toNumber(parsed);
      if (newValue !== oldValue && onChange) {
        onChange({ target: { value: newValue }});
      }
    }
  }

  return <TextField {...props}
      onBlur={handleBlur}
      onChange={onChange}
    />;
}