import React from 'react';
import classes from './Grid.module.scss';

export function Container({ className, children, ...props }) {
  var classNames = `${classes.container} ${className || ''}`;
  return <div {...props} className={classNames}>{children}</div>;
}

export function Row({ className, children, ...props }) {
  var classNames = `${classes.row} ${className || ''}`;
  return <div {...props} className={classNames}>{children}</div>;
}

const possibleValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'auto', true]

export function Col({ xs, sm, md, lg, xl, className, children, ...props }) {
  const valid = (v) => possibleValues.indexOf(v) >= 0;

  var classNames = [];
  if (valid(xs)) classNames.push(classes[xs === true ? 'col' : `col-${xs}`]);
  if (valid(sm)) classNames.push(classes[sm === true ? 'col-sm' : `col-sm-${sm}`]);
  if (valid(md)) classNames.push(classes[md === true ? 'col-md' : `col-md-${md}`]);
  if (valid(lg)) classNames.push(classes[lg === true ? 'col-lg' : `col-lg-${lg}`]);
  if (valid(xl)) classNames.push(classes[xl === true ? 'col-xl' : `col-xl-${xl}`]);
  if (!valid(xs) && !valid(sm) && !valid(md) && !valid(lg) && !valid(xl)) classNames.push(classes['col']);

  if (className) classNames.push(className);

  return <div {...props} className={classNames.join(' ')}>{children}</div>;
}

export function Flex({ grow, basis, wrap, alignItems, alignContent, justifyContent, children, ...props }) {
  var style = {
    display: 'flex'
  };

  if (grow) style.flexGrow = grow;
  if (basis) style.flexBasis = basis;
  if (wrap) style.flexWrap = 'wrap';
  if (alignItems) style.alignItems = alignItems;
  if (alignContent) style.alignContent = alignContent;
  if (justifyContent) style.justifyContent = justifyContent;

  style = { ...style, ...props.style };

  return <div {...props} style={style}>{children}</div>;
}