import React from 'react';
import { Typography, Box } from "@material-ui/core";

export default function AddStrategyComponent(props) {
  return (
    <div onClick={props.onClick} style={{cursor: "pointer", border: '2px dashed #bbbbbb', padding: '16px 16px 20px'}}>
      <Box paddingY={6}>
        <Typography variant="h3" align="center">
          +
        </Typography>
        <Typography variant="subtitle2" align="center">
          Add an Annuity
        </Typography>
      </Box>
    </div>
  );
};
