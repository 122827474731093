import React from "react";

export default class NumberFormatComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false
    };
  }

  formatValue = (value) => {
    var number = parseFloat(value);
    return !isNaN(number) ? number.toLocaleString() : "";
  }

  handleFocus = () => {
    this.setState({ focused: true });
  }

  handleBlur = () => {
    this.setState({ focused: false });

    if (this.props.onChange) {
      var value = parseFloat(this.props.value);
      this.props.onChange({ target: { value: !isNaN(value) ? `${value}` : "" }});
    }
  }

  render() {
    const inputProps = {
      ...this.props,
      ref: this.props.inputRef,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      value: (this.state.focused === true ? this.props.value : this.formatValue(this.props.value)) || ""
    };

    ['inputRef'].forEach(v => delete inputProps[v]);

    return (
      <input {...inputProps} />
    )
  }
}