import React, { useState, useLayoutEffect, useEffect } from 'react';
import classes from './Overlay.module.scss';

export default ({ children, onClose }) => {
  const [opened, setOpened] = useState(false);

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    return function cleanup() {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // transition overlay out after initially rendering with it collapsed
  useEffect(() => {
    setOpened(true);
  }, [setOpened]);

  // transition close first before updating parent components
  function close() {
    setOpened(false);
    setTimeout(() => onClose && onClose(), 250);
  }

  var className = [classes.container];
  if (opened === true) className.push(classes.opened); 

  return <>
    <div className={classes.overlay} onClick={() => close()}></div>
    <div className={className.join(' ')} onClick={(e) => e.stopPropagation()}>
        {children}
    </div>
  </>;
}