import React from "react";
import { TextField, Typography, Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";

export default class IndividualProfileComponent extends React.Component {
  setValue = (key, value) => {
    var currentAge = key === "currentAge" ? value : (this.props.value || {}).currentAge;
    var retirementAge = key === "retirementAge" ? value : (this.props.value || {}).retirementAge;
    var gender = key === "gender" ? value : ((this.props.value || {}).gender || "Male");
    var health = key === "health" ? value : ((this.props.value || {}).health || "Average");

    var profile = {
      currentAge: currentAge ? currentAge : null,
      retirementAge: retirementAge ? retirementAge : null,
      gender: gender ? gender : null,
      health: health ? health : null
    };

    if (this.props.onChange)
      this.props.onChange(profile);
  }

  render() {
    return (
      <div className={this.props.className}>
        <Typography variant="h6">{this.props.title}</Typography>

        <TextField
          required
          label="Current Age"
          helperText="From 25 - 85 years old"
          error={this.props.showErrors ? (this.props.errors || {}).currentAge : null}
          fullWidth
          value={(this.props.value || {}).currentAge || ""}
          onChange={(e) => this.setValue("currentAge", e.target.value)}
          margin="normal"
          inputRef={this.props.currentAgeRef}
          inputProps={{ maxLength: 2 }}
          className="currentAgeField"
        />

        <TextField
          required
          label="Anticipated Retirement Age"
          helperText="From 50 - 85 years old"
          error={this.props.showErrors ? (this.props.errors || {}).retirementAge : null}
          fullWidth
          value={(this.props.value || {}).retirementAge || ""}
          onChange={(e) => this.setValue("retirementAge", e.target.value)}
          margin="normal"
          inputProps={{ maxLength: 2 }}
          className="retirementAgeField"
        />
        
        <FormControl fullWidth margin="normal">
          <InputLabel>Your Gender</InputLabel>
          <Select value={(this.props.value || {}).gender || "Male"} onChange={(e) => this.setValue("gender", e.target.value)} className="genderField">
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl fullWidth margin="normal">
          <InputLabel>Your Health</InputLabel>
          <Select value={(this.props.value || {}).health || "Average"} onChange={(e) => this.setValue("health", e.target.value)} className="healthField">
            <MenuItem value="Poor">Very Poor</MenuItem>
            <MenuItem value="Average">Average</MenuItem>
            <MenuItem value="AboveAverage">Above Average</MenuItem>
            <MenuItem value="Excellent">Excellent</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  }
};
