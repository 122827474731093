const currencyRegex = /^[$]?(([0-9]+(?:,?[0-9]+)*)|[0-9]+)(?:\.[0-9]*)?$/;
const numberRegex = /^(([0-9]+(?:,?[0-9]+)*)|[0-9]+)(?:\.[0-9]*)?$/;

module.exports.isNumber = function(value) {
  return !!((value || '').match(numberRegex));
}

module.exports.isCurrency = function(value) {
  return !!((value || '').match(currencyRegex));
}

module.exports.toNumber = function(value) {
  return (value || '').toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

module.exports.toCurrency = function(value) {
  return (value || '').toLocaleString(undefined, { style: "currency", currency: "USD", minimumFractionDigits: 0, maximumFractionDigits: 0 });
}

module.exports.unformat = function(value) {
  if (value === undefined || value === null)
    return value;

  var unformatted = value.replace(/[^0-9.]/g, '');
  return parseFloat(unformatted);
}
