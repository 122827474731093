import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import classes from './Modal.module.scss';

export default function Modal({ open, title, actions, size = 'md', scrollable, onClose, children }) {
  const [backdrop, setBackdrop] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [closing, setClosing] = useState(false);

  // transition overlay and backdrop upon open prop change
  const isFirstRun = useRef(true);
  useEffect(() => {
    // ignore if initial open state is hidden, no need to run the close animations
    if (isFirstRun.current === true && !open) {
      isFirstRun.current = false;
      return;
    }

    if (open) {
      setBackdrop(true);
      setOverlay(true);
    }
    else {
      setOverlay(false);
      setTimeout(() => setBackdrop(false), 150);
    }
  }, [open, setOverlay, setBackdrop]);

  // separate overflow setting relative to when backdrop/overlays are set after timers
  useLayoutEffect(() => {
    if (backdrop || overlay) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'auto';
    }

    return function cleanup() { 
      document.body.style.overflow = 'auto';
    };
  }, [backdrop, overlay]);


  if (!open && !backdrop && !overlay)
    return null;


  const backdropClasses = [classes['modal-backdrop'], classes.fade];
  if (backdrop) backdropClasses.push(classes.show);

  const modalClasses = [classes['modal'], classes.fade];
  if (overlay) modalClasses.push(classes.show);

  const dialogClasses = [classes['modal-dialog']];
  if (scrollable) dialogClasses.push(classes['modal-dialog-scrollable']);
  if (size === 'sm') dialogClasses.push(classes['modal-sm']);
  if (size === 'lg') dialogClasses.push(classes['modal-lg']);
  if (size === 'xl') dialogClasses.push(classes['modal-xl']);

  return <>
    <div className={backdropClasses.join(' ')}></div>
    <div className={modalClasses.join(' ')} tabIndex="-1" onMouseDown={() => setClosing(true)} onMouseUp={() => { if (closing === true) { onClose && onClose() } setClosing(false); }}>
      <div className={dialogClasses.join(' ')} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        <div className={classes['modal-content']}>
          <div className={classes['modal-header']}>
            <h5 className={classes['modal-title']}>{title}</h5>
            {onClose && 
              <button type="button" className={classes['close']} onClick={() => onClose()}>
                <span>&times;</span>
              </button>
            }
          </div>
          <div className={classes['modal-body']}>
            {children}
          </div>
          <div className={classes['modal-footer']}>
            {actions}
          </div>
        </div>
      </div>
    </div>
  </>;
}