import React from "react";
import { Container, Row, Col } from './Grid';
import classes from './Footer.module.scss';

export default function FooterComponent(props) {

  return (
    <footer className={classes.footer}>
      <Container>
        <Row>
          <Col xs={12} className={classes.disclaimer}>
            <div>
              <p>General Disclaimer</p>
              <p>
                  Milliman Financial Risk Management LLC does not make any representations that products or services
                  described
                  or referenced herein are suitable or appropriate for the recipient. Many of the products and services
                  described or referenced herein involve significant risks, and the recipient should not make any decision
                  or
                  enter into any transaction unless the recipient has fully understood all such risks and has independently
                  determined that such decisions or transactions are appropriate for the recipient. Any discussion of risks
                  contained herein with respect to any product or service should not be considered to be a disclosure of all
                  risks or a complete discussion of the risks involved. The recipient should not construe any of the
                  material
                  contained herein as investment, hedging, trading, legal, regulatory, tax, accounting or other advice. The
                  recipient should not act on any information in this document without consulting its investment, hedging,
                  trading, legal, regulatory, tax, accounting or other advisors.
              </p>
            </div>
          </Col>

          <Col xs={12} md={4} className={classes.secondary}>
            <p>&copy; 2019 Milliman, Inc. All Rights Reserved</p>
          </Col>
          <Col xs={12} md={3} className={classes.secondary}>
            <a href="https://www.milliman.com/copyright-information" target="_blank" rel="noopener noreferrer">Copyright information</a>
          </Col>
          <Col xs={12} md={3} className={classes.secondary}>
            <a href="https://www.milliman.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of use</a>
          </Col>
          <Col xs={12} md={2} className={classes.secondary}>
            <a href="https://www.milliman.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy policy</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
