import React from "react";
import { InputBase, Typography, Slider } from '@material-ui/core';
import { Row, Col } from '../../Shared/Grid';

export default class SimpleAssetLineItemComponent extends React.Component {
  render() {
    return (
      <div className={this.props.className} style={{display: "flex"}}>
        <div style={{width: 10, height: 50, marginRight: 12, backgroundColor: this.props.color, borderRadius: 2}}></div>

        <div style={{flexGrow: 1}}>
          <Row>
            <Col xs={9}>
              <Typography>{this.props.label}</Typography>
            </Col>

            <Col xs={3}>
              <InputBase
                inputRef={this.props.allocationRef}
                value={this.props.value || "0"}
                onChange={e => { if (this.props.onChange) this.props.onChange(e, Math.min(Math.max(+e.target.value, 0), 100)) }}
                endAdornment='%'
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 100,
                  type: 'number',
                  style: { textAlign: 'right' }
                }}
                fullWidth
                className="amountField"
              />
            </Col>
          </Row>

          <div style={{marginLeft: 6, marginRight: 6}}>
            <Slider
              value={this.props.value || 0}
              onChange={this.props.onChange}
              valueLabelDisplay="auto"
              valueLabelFormat={n => `${n}%`}
              className="sliderField"
            />
          </div>
        </div>
      </div>
    );
  }
}