import React, { useState, useEffect, useCallback } from 'react';
import Modal from '../../Shared/Modal';
import { Row, Col } from '../../Shared/Grid';
import { Group, Paragraph, Label } from '../../Shared/Typography';
import { Button } from '../../Shared/Controls';
import { NumberField } from '../../Shared/CustomControls';
import FormatService from '../../services/FormatService';
import { hasErrors, getValidState, isInRange } from '../../services/ValidationService';

export default function IncomeModal({ value, onAccept, onUnset, onClose }) {
  const { open, age, isSet, amount } = value;
  const [internalValue, setInternalValue] = useState(FormatService.toCurrency(amount) || '');
  const [liveValidation, setLiveValidation] = useState(false);
  const [errors, setErrors] = useState(validate());

  useEffect(() => setInternalValue((FormatService.toCurrency(amount) || '') + ''), [amount]);

  const memoizedValidate = useCallback(validate, [internalValue]);
  useEffect(() => {
    if (liveValidation === true) {
      setErrors(memoizedValidate());
    }
  }, [internalValue, liveValidation, memoizedValidate]);

  function validate() {
    var errors = {};
    if (!isInRange(internalValue, 0, 999999999, true)) errors.internalValue = true;
    return errors;
  }

  function handleAccept() {
    var errors = validate();
    if (hasErrors(errors)) {
      setErrors(errors);
      if (liveValidation === false) setLiveValidation(true);
    }
    else {
      onAccept && onAccept(age, FormatService.unformat(internalValue));
      setLiveValidation(false);
    }
  }

  function handleUnset() {
    isSet && onUnset && onUnset(age);
    setLiveValidation(false);
  }

  function handleClose() {
    onClose && onClose();
    setLiveValidation(false);
  }

  return (
    <Modal
      open={open}
      size="md"
      scrollable={true}
      title={`Earnings At Age ${age}`}
      onClose={handleClose}
      actions={
        <Row style={{ justifyContent: 'space-between' }}>
          <Col xs={4} lg={3}>
            <Button className="cancelButton" variant="outline" onClick={handleClose} style={{ width: '100%' }}>Cancel</Button>
          </Col>
          <Col xs={7} lg={5}>
            <Row>
              <Col xs={6}>
                {isSet && <Button className="unsetButton" variant="danger-outline" onClick={handleUnset} style={{ width: '100%' }}>Unset</Button>}
              </Col>
              <Col xs={6}>
                <Button className="applyButton" onClick={handleAccept} style={{ width: '100%' }}>Apply</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Paragraph>What were your earnings at age {age}?</Paragraph>
      <Row>
        <Col xs={6}>
          <Group>
            <Label>Amount</Label>
            <NumberField className="amountField" value={internalValue} isCurrency placeholder="$0" onChange={e => setInternalValue(e.target.value)} valid={getValidState('internalValue', errors, liveValidation)} />
          </Group>
        </Col>
      </Row>
    </Modal>
  );
}