import React from "react";
import { TextField, InputAdornment } from '@material-ui/core';
import constants from '../../constants';

export default function TimeBasedAssetLineItemComponent(props) {
  var value = props.value || {};
  
  function handleChange(asset, value) {
    if (props.onChange) {
      var newValue = JSON.parse(JSON.stringify(props.value)); 
      newValue[asset] = value;
      props.onChange(newValue);
    }
  };

  return (
    <tr className={props.className}>
      <td style={{whiteSpace: 'nowrap', verticalAlign: 'bottom'}}>
        {value.timeSteps !== null ?
          <>
            {!!props.isFirst ? <>First</> : <>Next</>}
            <TextField
              value={value.timeSteps || ""}
              onChange={e => { handleChange("timeSteps", Math.min(Math.max(parseInt(e.target.value), 0), 30)) }}
              error={props.showErrors ? props.errors.timeSteps : null}
              inputProps={{ style: { textAlign: "center" } }}
              style={{width: 18, marginLeft: 4, marginRight: 4, verticalAlign: 'middle'}}
              className="yearField"
            />
          </>
          : (!!props.isFirst ? <>Start to End</> : <>Until End</>)
        }
      </td>

      {constants.savings.map((s, index) =>
        <td key={`line-${index}`} style={{verticalAlign: 'bottom', paddingLeft: 4, paddingRight: 4}}>
          <TextField
            inputRef={index === 0 ? props.allocationRef : null}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { style: { textAlign: 'right' } }
            }}
            value={value[s.id] || "0"}
            onChange={e => { handleChange(s.id, Math.min(Math.max(+e.target.value, 0), 100)) }}
            fullWidth
            className={`allocationField-${s.id}`}
          />
        </td>
      )}

      <td style={{paddingLeft: 4}}>
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputProps: { style: { textAlign: 'right' } }
          }}
          value={constants.savings.reduce((total, assetType) => total + (value[assetType.id] || 0), 0)}
          error={props.showErrors ? props.errors.allocation : null}
          readOnly={true}
          className="totalField"
          fullWidth
        />
      </td>
    </tr>
  );
}